import React, { createContext, useState } from 'react';

const LanguageContext = createContext({
  language: 'en', // Initial language
  setLanguage: (lang) => {}, // Function to update language
});

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Initial language state

  const changeLanguage = (newLang) => {
    setLanguage(newLang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };