import {
    GETGAME_SUCCESS,
    GETGAME_FAIL,
    EDITGAME_SUCCESS,
    EDITGAME_FAIL,
    DELETEGAME_SUCCESS,
    DELETEGAME_FAIL,
    GETGAMEITEM_SUCCESS,
    GETGAMEITEM_FAIL,
    CREATEGAME_SUCCESS,
    CREATEGAME_FAIL
} from "../actions/types";

const initialState = [];

export default function gameReducer(game = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEGAME_SUCCESS:
            return {currentGame:item}
        case CREATEGAME_FAIL:
            return game;
        case GETGAME_SUCCESS:
            return {...list};
        case GETGAME_FAIL:
            return game;
        case GETGAMEITEM_SUCCESS:
            return {...item};
        case GETGAMEITEM_FAIL:
            return game;
        case EDITGAME_SUCCESS:
            return game;
        case EDITGAME_FAIL:
            return game;
        case DELETEGAME_SUCCESS:
            return game;
        case DELETEGAME_FAIL:
            return game;
        default:
            return game;
    }

}