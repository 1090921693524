import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Icon } from "../../components/Icon";
import SmallModal from "../../components/SmallModal/SmallModal";
import { editUser, getUserDetail } from "../../redux/actions/user";

const UserEditPage = () => {
    const user = useSelector((state) => state.user?.currentUser?.user || {});
    const fullList = useSelector((state) => state.fullList);
    const [ready, setReady] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [validForm, setValidForm] = useState({});
    const [showRepeat, setShowRepeat] = useState(false);
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentId = useParams().id;
    const { t } = useTranslation();

    const changeHandler = (event) => {
        const { id, value } = event.target;
        if (event.target.tagName === 'SELECT' && event.target.accessKey) {
            setForm({ ...form, [event.target.accessKey]: value });
        } else {
            setForm({ ...form, [id]: value });
        }
    };

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.password) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };

    const initFunc = () => {
        dispatch(getUserDetail({ id: currentId })).then(res => {
            setReady(true);
            setForm(res.user);
        }).catch(e => {
            setReady(true);
            console.log(e);
        });
    };

    const saveClick = () => {
        let data = { ...form };
        dispatch(editUser(data)).then(res => {
            setSuccess(true);
        }).catch(e => {
            console.log(e);
        });
    };

    const declineClick = () => {
        navigate('/users');
    };

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready]);

    return (
        <>
            {success && 
                <SmallModal text='success' class='success' setSuccess={()=>setSuccess(false)}></SmallModal>
            }
            {ready && (
                <div className="profileMainBlock prFlexRow">
                    <div className="photoUploadCover">
                        <Icon type='photoUpload' />
                    </div>
                    <div className="profileMainBlockInfo prFlexRow">
                        <div className="headerUserAvatarBlock">
                            <div className='headerUserAvatar'>
                                <Icon type='avatar' />
                            </div>
                            <div className="headerUserAvatarUpload">
                                <Icon type='photoUpload' />
                            </div>
                        </div>
                        <div className="profileMainBlockInfoRight">
                            <div className="prFlexRow profileMainBlockInfoRightText">
                                <div className='prFlexRow'>
                                    <div 
                                    className={
                                        (user?.reputation > 6 && "reputationNumber") ||
                                        ((user?.reputation < 7 && user?.reputation > 3) && "reputationNumber caution") ||
                                        (user?.reputation < 4 && "reputationNumber error")
                                    }
                                    >{user.reputation}</div>
                                </div>
                                <div className="profileFio">{t('last_name')} {t('first_name')}</div>
                                <div style={{ paddingTop: '3px' }} className='profileFioEditName'>
                                    <Icon type='editName' />
                                </div>
                            </div>
                            <div className="profileMainBlockInfoRightNick">
                                @{user?.nickname}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="userEditBlock" style={{ width: '100%' }}>
                <div className="userEditLeft">
                    <h2>{t('user_info')}
                        <div className="infoHelp"><Icon type='info' /></div>
                    </h2>
                    <div className="prFlexRow">
                        <div>
                            <div className="prInput">
                                <label htmlFor="role" className={errors.role ? "prInputLabel inputError" : "prInputLabel"}>{t("role")}</label>
                                <select
                                    className={errors.role ? "prInputInput inputError" : "prInputInput"}
                                    id="role"
                                    value={form.role || ""}
                                    onChange={changeHandler}
                                >
                                    <option value="">{t('empty_select')}</option>
                                    {fullList.UserRoles.length > 0 &&
                                        fullList.UserRoles.map(el => (
                                            <option key={el.id} value={el.id}>{el.title}</option>
                                        ))
                                    }
                                </select>
                                {errors.role && <div className="error">{errors.role}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                                {errors.nickname && <div className="error">{errors.nickname}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                                {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                                {errors.birthday && <div className="error">{errors.birthday}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                <select
                                    className={errors.sex ? "prInputInput inputError" : "prInputInput"}
                                    id="sex"
                                    value={form.sex || ""}
                                    onChange={changeHandler}
                                >
                                    <option value="">{t('empty_select')}</option>
                                    {fullList.Sex.length > 0 &&
                                        fullList.Sex.map(el => (
                                            <option key={el.id} value={el.id}>{el.title}</option>
                                        ))
                                    }
                                </select>
                                {errors.sex && <div className="error">{errors.sex}</div>}
                            </div>
                        </div>
                        <div>
                            <div className="prInput">
                                <label htmlFor="first_name" className={errors.first_name ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="first_name" type="text" className={errors.first_name ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                {errors.first_name && <div className="error">{errors.first_name}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="last_name" className={errors.last_name ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="last_name" type="text" className={errors.last_name ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                {errors.last_name && <div className="error">{errors.last_name}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                <select
                                    className={errors.country ? "prInputInput inputError" : "prInputInput"}
                                    id="country"
                                    value={form.country || ""}
                                    onChange={changeHandler}
                                >
                                    <option value="">{t('empty_select')}</option>
                                    <option value="Україна">Україна</option>
                                    <option value="Інша країна">Інша країна</option>
                                </select>
                                {errors.country && <div className="error">{errors.country}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                <select
                                    className={errors.time_line ? "prInputInput inputError" : "prInputInput"}
                                    id="time_line"
                                    value={form.time_line || ""}
                                    onChange={changeHandler}
                                >
                                    <option value="">{t('empty_select')}</option>
                                    <option value="+1">+1</option>
                                    <option value="+2">+2</option>
                                </select>
                                {errors.time_line && <div className="error">{errors.time_line}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="userEditRight">
                    <h2>{t('set_password')}
                        <div className="infoHelp"><Icon type='info' /></div>
                    </h2>
                    <div className="prInput prInputIcon">
                        <label htmlFor="password" className={errors.password ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                        <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="password" type={show ? 'text' : "password"} className={errors.password ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                        <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <div className="prInput prInputIcon">
                        <label htmlFor="passwordRepeat" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                        <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeat" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                        <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                    </div>
                    <button className="prButton prButtonMain">{t('save_password')}</button>
                </div>
            </div>
            <div className="userEditButtons">
                <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
            </div>
        </>
    );
};

export default UserEditPage;
