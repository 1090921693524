import {
  GETCURRENTUSERDETAIL_FAIL,
  GETCURRENTUSERDETAIL_SUCCESS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETCURRENTUSERDETAIL_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case GETCURRENTUSERDETAIL_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
}
