import {
  CREATEROOM_SUCCESS,
  CREATEROOM_FAIL,
  GETROOMLIST_SUCCESS,
  GETROOMLIST_FAIL,
  GETROOMDETAIL_SUCCESS,
  GETROOMDETAIL_FAIL,
  SETROOMLIST_SUCCESS
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATEROOM_SUCCESS:
      return {
        ...state,
        currentRoom:payload
      };
    case SETROOMLIST_SUCCESS:
      return {
        ...state,
        roomsList:payload
      };
    case CREATEROOM_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
}
