import React, { useEffect, useState } from 'react';
import UseRouts from './routes';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HeaderAuth from './components/Header/HeaderAuth';
import { socket } from './socket';
import { setRoomList } from './redux/actions/room';
import { getCurrentUserDetail } from './redux/actions/user';
import { getFullList } from './redux/actions/dictionaries';
import { Icon } from './components/Icon';
import { errorFree } from './redux/actions/auth';
import { useTranslation } from 'react-i18next';
import SmallModal from './components/SmallModal/SmallModal';



function App() {
  const auth = useSelector((state) => state.auth);
  const message = useSelector((state) => state.error.message);
  const [ready, setReady] = useState(false);
  const [topic, setTopic] = useState('white');
  const [errorBack, setErrorBack] = useState('');
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [itemList, setItemList] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const errorClose =()=>{
    dispatch(errorFree()).then(res=>{
      setErrorBack('')
    })
  }
  useEffect(() => {
    if(message?.length>0){
      console.log(message);
      setErrorBack(message);
    }
  }, [message]);

  useEffect(() => {
    if(localStorage.getItem('Theme')){
      setTopic(localStorage.getItem('Theme'));
    }
  }, []);

  useEffect(() => {
    function onConnect() {
      console.log('connected');
      setIsConnected(true);
    }
    function onDisconnect() {
      console.log('disconnected');
      setIsConnected(false);
    }
    function onFooEvent(value) {
      console.log('foo');
      console.log(value);
      setFooEvents((previous) => [...previous, value]);
    }
    function onItemList(data) {
      // console.log('Received item list:', data);
      dispatch(setRoomList(data));
      setItemList(data);
    }
    function onError(data){
      alert(data);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('foo', onFooEvent);
    socket.on('roomsList', onItemList);
    socket.on('error', onError);
    


    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('foo', onFooEvent);
    };
  }, []);

  useEffect(() => {
    if (!ready) {
      dispatch(getFullList()).then(res=>{
        setReady(true);
      })
    }
  }, []);

  useEffect(() => {
    if (auth.isLoggedIn ) {
      socket.emit('userLoggedIn', { isLoggedIn: auth.isLoggedIn, accessToken:auth.user.accessToken });
      dispatch(getCurrentUserDetail({id:auth.user.user.id}))
    } else {
      socket.emit('userLoggedOut', { isLoggedIn: auth.isLoggedIn });
    }
  }, [auth.isLoggedIn,isConnected]);

  return (
    <div className={topic=='white'?"App":"App DarkTopic"} >
      {!auth.isLoggedIn && <Header  topic={topic} setTopic={setTopic} isConnected={isConnected}/>}
      {auth.isLoggedIn && <HeaderAuth  topic={topic} setTopic={setTopic} isConnected={isConnected}/>}
      <div className={auth.isLoggedIn?"main auth":"main"}>
        {ready && <UseRouts  isLoggedIn={auth.isLoggedIn}/>}
      </div>
      <Footer />

      {errorBack && 
          <SmallModal text={errorBack} class='error' setSuccess={errorClose}></SmallModal>
      }
      
    </div>
  );
}

export default App;
