import {
    GETROOMDETAIL_SUCCESS,
    GETROOMDETAIL_FAIL,
  } from "../actions/types";
  
  const initialState = {};
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GETROOMDETAIL_SUCCESS:
        return {
          ...state,
          currentRoom:payload
        };
      case GETROOMDETAIL_FAIL:
        return {
          ...state
        };
      default:
        return state;
    }
  }
  