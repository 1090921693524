import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { createRoom } from "../../../redux/actions/room";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const ProfileRoomsPage=()=>{
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [sort,setSort]=useState('asc');
    const [ready, setReady] = useState(false);
    const [sortValue,setSortValue]=useState('createdAt');
    const [filterCreate, setFilterCreate] = useState({});
    const dispatch = useDispatch();

    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("profileRoomsPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dataBirthdayFrom" || id === "dataBirthdayTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
        localStorage.setItem("profileRoomsPage", JSON.stringify(updatedFilter));
    };


    const changeHandlerCreate = (e) => {
        const { id, value } = e.target;
        let updatedFilterCreate = { ...filterCreate };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilterCreate[e.target.accessKey] = value;
        }
        else if (value.length<1) {
            delete updatedFilterCreate[id];
        }
        else {
            updatedFilterCreate[id] = value;
        }
        setFilterCreate(updatedFilterCreate);
    };

    const selectClick=(e)=>{
        e.preventDefault();
        let arr = [];
        if(filterCreate.platform?.length>0){
            arr=[...filterCreate.platform]
        }
        let el = e.target.id;
        if (arr.indexOf(el) !== -1) {
            arr = arr.filter(item => item !== el);
        } else {
            arr.push(el);
        }
        setFilterCreate({
            ...filterCreate,
            platform: arr
        });
    }

    const initFunc = (page = 1) => {
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        data.sort[sortValue]=sort;

        // dispatch(getUsersList(data)).then(() => {
            setReady(true);
        // });
    };

    const createFunc =()=>{
        dispatch(createRoom(filterCreate)).then(res=>{
        })
    }

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready]);

    const resetFilter=()=>{
        localStorage.removeItem('profileRoomsPage');
        setFilter({});
    }
    const resetFilterCreate=()=>{
        setFilterCreate({});
    }


    return(<>
        <div className="profileSection profileSectionBg">
            <h2>{t('actual_rooms')}
                <div className="infoHelp"><Icon type='info'/></div>
            </h2>
            <div className="roomList">
                <div className="roomItem prFlexRow">
                    <div className="roomItemLink">ML02153658745</div>
                    <div className="roomItemIcon">
                        <Icon type='time'></Icon>
                    </div>
                    <div className="roomItemTime">
                        05:22
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('game')}</div>
                        <div className="roomItemBlockValue">Dota 2</div>
                    </div>
                    <div className="roomItemMoney">
                        <div className="roomItemBlockHeader">{t('input_money')}</div>
                        <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('type')}</div>
                        <div className="roomItemBlockValue">Приватна</div>
                    </div>
                    <div className="roomItemIcon roomItemBlock">
                        <Icon type='xbox'></Icon>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('format')}</div>
                        <div className="roomItemBlockValue">1 х 1</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">Пошук учасників</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div>
                <div className="roomItem prFlexRow">
                    <div className="roomItemLink">ML02153658745</div>
                    <div className="roomItemIcon">
                        <Icon type='time'></Icon>
                    </div>
                    <div className="roomItemTime">
                        05:22
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('game')}</div>
                        <div className="roomItemBlockValue">Dota 2</div>
                    </div>
                    <div className="roomItemMoney">
                        <div className="roomItemBlockHeader">{t('input_money')}</div>
                        <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('type')}</div>
                        <div className="roomItemBlockValue">Приватна</div>
                    </div>
                    <div className="roomItemIcon roomItemBlock">
                        <Icon type='xbox'></Icon>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('format')}</div>
                        <div className="roomItemBlockValue">1 х 1</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">Пошук учасників</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div>                 
            </div>
        </div>


        <div className="profileSection">
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('close_rooms')}
                <div className="infoHelp"><Icon type='info'/></div></h2>
                <div className="prFlexRow" style={{gap:'25px'}}>
                    <button className="prButton prButtonMain" onClick={() => setIsOpenCreate(true)}>{t('create_room')}</button>
                    <button className="prButton prButtonMainP prButtonSecond prFlexRow" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
                {/* <a className="profileSectionHeaderLink link">{t('all_games')}<Icon type='rightarrow'/></a> */}
            </div>
            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dataBirthdayFrom" value={(filter.dataBirthdayFrom?.length>1 && getDate(filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dataBirthdayTo" value={(filter.dataBirthdayTo?.length>1 && getDate(filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <div className="filterBlockSortValue prFlexRow">
                                <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                                <div>{sortOptions[0].value}</div>
                                <Icon type="selectArrow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {} {t("filter_all")} {}
                </div>
            </div>
            <div className="roomList">
                <div className="roomItem prFlexRow">
                    <div className="roomItemLink">ML02153658745</div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('game')}</div>
                        <div className="roomItemBlockValue">Dota 2</div>
                    </div>
                    <div className="prFlexRow">
                        <div className="roomItemMoney">
                            <div className="roomItemBlockHeader">{t('input_money')}</div>
                            <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                        </div>
                        <div className={true?"roomItemResult loose":"roomItemResult win"}>
                            <div className="roomItemBlockHeader">{t('result')}</div>
                            <div className="roomItemMoneyValue prFlexRow "><Icon type={true?'loose':'win'}></Icon> {true?'-':'+'} 35$</div>
                        </div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('type')}</div>
                        <div className="roomItemBlockValue">Приватна</div>
                    </div>
                    <div className="roomItemIcon roomItemBlock">
                        <Icon type='pc'></Icon>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('format')}</div>
                        <div className="roomItemBlockValue">1 х 1</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('role_room')}</div>
                        <div className="roomItemBlockValue">Організатор</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div> 
                <div className="roomItem prFlexRow">
                    <div className="roomItemLink">ML02153658745</div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('game')}</div>
                        <div className="roomItemBlockValue">Dota 2</div>
                    </div>
                    <div className="prFlexRow">
                        <div className="roomItemMoney">
                            <div className="roomItemBlockHeader">{t('input_money')}</div>
                            <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                        </div>
                        <div className={false?"roomItemResult loose":"roomItemResult win"}>
                            <div className="roomItemBlockHeader">{t('result')}</div>
                            <div className="roomItemMoneyValue prFlexRow "><Icon type={false?'loose':'win'}></Icon> {false?'-':'+'} 35$</div>
                        </div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('type')}</div>
                        <div className="roomItemBlockValue">Приватна</div>
                    </div>
                    <div className="roomItemIcon roomItemBlock">
                        <Icon type='pc'></Icon>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('format')}</div>
                        <div className="roomItemBlockValue">1 х 1</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('role_room')}</div>
                        <div className="roomItemBlockValue">Організатор</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div>                 
            </div>
        </div>
        {isOpen && <Filter type='rooms' resetFilter={resetFilter} accept={initFunc} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {isOpenCreate && <Filter type='createRooms' selectClick={selectClick} needCheckedC={true} resetFilter={resetFilterCreate} accept={createFunc} filter={filterCreate} setFilter={setFilterCreate} onChange={changeHandlerCreate} isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} />}
    </>)

}

export default ProfileRoomsPage;