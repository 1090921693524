import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



const Page404 = ()=>{
    const navigate = useNavigate();
    const { t } = useTranslation();
      const cancelHandler=(e)=>{
        navigate('/login')
      }
    return(
    <div className="prFullHeight">
      <div className='page404'>404</div>
      <div className='page404Text'>{t("404_notFound")}</div>
      <div className='page404Button'>
        <button onClick={cancelHandler} className="prButton prButtonSecond" style={{marginBottom:'60px',width:'340px', textTransform:'none', height:'48px'}}>{t("404_main")}</button>
      </div>
    </div>
    )

}

export default Page404;