import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Editor from "../../components/Editor/Editor";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Icon } from "../../components/Icon";
import { addGame, deleteGame, editGame, getGameItem } from "../../redux/actions/game";

const GameEditPage = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({ formats: [] });
  const [formats, setFormats] = useState({});
  const [edit, setEdit] = useState(null); // To track the editing format index
  const [errors, setErrors] = useState({});
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const currentId= useParams().id;
  const navigate=useNavigate();
  const fullList = useSelector((state) => state.fullList);

  const changeHandler = (event) => {
    const { id, value } = event.target;
    setForm({ ...form, [id]: value });
  };

  const changeHandlerFormats = (event) => {
    const { id, value } = event.target;
    setFormats({ ...formats, [id]: value });
  };

  const saveClick = () => {
    if (formats.new && formats.new.trim() !== "") {
      let arr = [...form.formats];
      arr.push(formats.new);
      setFormats({});
      setForm({ ...form, formats: [...arr] });
    }
  };

  const deleteFormats = (index) => {
    const updatedFormats = [...form.formats];
    updatedFormats.splice(index, 1);
    setForm({ ...form, formats: updatedFormats });
  };

  const handleEditFormats = (index) => {
    setEdit(index); // Set the current format being edited
    setFormats({ editValue: form.formats[index] }); // Pre-fill the input with the current value
  };

  const saveEditedFormats = (index) => {
    const updatedFormats = [...form.formats];
    updatedFormats[index] = formats.editValue; // Update the edited format
    setForm({ ...form, formats: updatedFormats });
    setEdit(null); // Exit edit mode
  };


  const savedClick =()=>{
      console.log(form);
      if(currentId==1){
        dispatch(addGame(form)).then(res=>{
            console.log(res);
            navigate('/games')
          })
      }else{
          dispatch(editGame(form)).then(res=>{
            console.log(res);
            navigate('/games')
          })
      }
     
  }

  const deleteClick =()=>{
    if(currentId==1){
        navigate('/games')
    }else{
        dispatch(deleteGame(form)).then(res=>{
            console.log(res);
            navigate('/games')
          })
    }
}

const cancelCLick =()=>{
    navigate('/games');
}
const deactivateClick =()=>{
    form.isActive=0;
    dispatch(editGame(form)).then(res=>{
        console.log(res);
        navigate('/games')
      })
}
const publicClick =()=>{
    form.isActive=1;
    dispatch(editGame(form)).then(res=>{
        console.log(res);
        navigate('/games')
      })
}

  const selectClick = (e) => {
    let arr = form.platforms ||[];
    let el = e.target.id;

    if (arr.indexOf(el) !== -1) {
        arr = arr.filter(item => item !== el);
    } else {
        arr.push(el);
    }
    setForm({
        ...form,
        platforms: arr
    });
};

    const customSave = (e) => {
        let resp = { ...form };
        console.log(e);
        resp.description = e;
        setForm(resp);
    };
    const initFunc =()=>{
        dispatch(getGameItem({id:currentId})).then(res=>{
            setForm(res.game);
            setReady(true);
        })
    }

    useEffect(() => {
        if(currentId !=1 && !ready){
            initFunc();
        }else if( !ready && currentId ==1){
            setReady(true);
        }
    }, [ready,currentId]);
if(ready){
    return (
        <>
          <div className="profileSection" style={{ marginBottom: "0px" }}>
            <div className="prFlexRow profileSectionHeader" style={{ marginBottom: "40px" }}>
              <h2 className="profileSectionHeaderH">{t("create_new_game")}</h2>
            </div>
            <div>
              <div className="dFlex" style={{ gap: "15px" }}>
                <div>
                  <div className="dFlex" style={{ gap: "60px" }}>
                    <div>
                      <div className="prInput">
                        <label htmlFor="name" className={errors.name ? "prInputLabel inputError" : "prInputLabel"}>
                          {t("game_name")}
                        </label>
                        <input
                          value={form?.name || ""}
                          onChange={changeHandler}
                          id="name"
                          type="text"
                          className={errors.name ? "prInputInput inputError" : "prInputInput"}
                          placeholder={t("game_name")}
                        />
                        {errors.name && <div className="error">{errors.name}</div>}
                      </div>
    
                      <div className="prInput">
                        <label htmlFor="termExistence" className={errors.termExistence ? "prInputLabel inputError" : "prInputLabel"}>
                          {t("game_time_live")}
                        </label>
                        <input
                          value={form?.termExistence || ""}
                          onChange={changeHandler}
                          id="termExistence"
                          type="text"
                          className={errors.time ? "prInputInput inputError" : "prInputInput"}
                          placeholder={t("game_time_live")}
                        />
                        {errors.time && <div className="error">{errors.termExistence}</div>}
                      </div>
                    </div>
    
                    <div>
                        <div className="prInput" style={{ marginBottom: "-9px" }}>
                            <label htmlFor="platform" className={errors.platform ? "prInputLabel inputError" : "prInputLabel"}>
                            {t("platform")}
                            </label>
                        </div>
                        {fullList.Platform.map((el) => (
                            <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                            <div
                                id={el.id}
                                onClick={selectClick}
                                className={form['platforms']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                            ></div>
                            <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={selectClick}>
                                {t(el.title)}
                            </div>
                            </div>
                        ))}
                        </div>
                    </div>
    
                  <div className="prInput">
                    <label htmlFor="popular" className={errors.popular ? "prInputLabel inputError" : "prInputLabel"}>
                      {t("add_popular_game")}
                    </label>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="popularSwitch"
                        checked={form.isPopularGame==1}
                        onChange={() => setForm({ ...form, isPopularGame: form.isPopularGame?0:1 })}
                        />
                        <span className="slider round"></span>
                       {form.isPopularGame==1?<span className="switchText left">Так</span>:
                        <span className="switchText right">Ні</span>}
                    </label>
                  </div>
                  <div className="prInput">
                    <label htmlFor="attention" className={errors.attention ? "prInputLabel inputError" : "prInputLabel"}>
                      {t("attention")}
                    </label>
                    <textarea
                      style={{ margin: 0 }}
                      onChange={changeHandler}
                      id="attention"
                      value={form?.attention || ""}
                      placeholder={t("enter_value")}
                      cols="30"
                      rows="10"
                    ></textarea>
                    {errors.attention && <div className="error">{errors.attention}</div>}
                  </div>
                </div>
    
                <div style={{ flex: "0 0 48%" }}>
                  <div className="prInput" style={{ marginBottom: "-9px" }}>
                    <label htmlFor="game_format" className={errors.game_format ? "prInputLabel inputError" : "prInputLabel"}>
                      {t("game_format")}
                    </label>
                    <div key={"id"} className="directoryElememt directoryElememtGame prFlexRow">
    
                      <>
                        <div className="directoryElementText">
                          <input
                            className="prInputInput"
                            id={"new"}
                            value={formats.new || ""}
                            onChange={changeHandlerFormats}
                            placeholder={t("game_name")}
                            type="text"
                          />
                        </div>
                        <div className="prFlexRow directoryElementButton">
                          <div className="prIconButton" id={"new"} onClick={saveClick}>
                            <Icon type="accept"></Icon>
                          </div>
                          <div className="prIconButton" onClick={() => setFormats({})}>
                            <Icon type="close"></Icon>
                          </div>
                        </div>
                      </>
                    </div>
                    {form?.formats?.length > 0 &&
                      form.formats.map((el, index) => (
                        <div key={index} style={{ padding: "12px 0 12px 12px" }} className="directoryElememt directoryElememtGame prFlexRow">
                          {edit === index ? (
                            <>
                              <input
                                className="prInputInput"
                                value={formats.editValue || ""}
                                onChange={(e) => setFormats({ ...formats, editValue: e.target.value })}
                              />
                              <div className="prFlexRow directoryElementButton">
                                <div className="prIconButton" onClick={() => saveEditedFormats(index)}>
                                  <Icon type="accept" />
                                </div>
                                <div className="prIconButton" onClick={() => setEdit(null)}>
                                  <Icon type="close" />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="directoryElementText">{el}</div>
                              <div className="prFlexRow directoryElementButton">
                                <div className="prIconButton" onClick={() => handleEditFormats(index)}>
                                  <Icon type="edit" />
                                </div>
                                <div className="prIconButton" onClick={() => deleteFormats(index)}>
                                  <Icon type="close" />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
                <div className="dFlex">
                  <div className="prInput"  style={{ flex: "0 0 48%" }}>
                    <label htmlFor="game_icon" className={errors.game_icon ? "prInputLabel inputError" : "prInputLabel"}>
                      {t("game_icon")}
                    </label>
                    <FileUpload id={'iconGame'} imageUrl={form.iconGame} form={form} setForm={setForm}></FileUpload>
                  </div>
    
                  <div className="prInput"  style={{ flex: "0 0 48%" }}>
                    <label htmlFor="main_image" className={errors.main_image ? "prInputLabel inputError" : "prInputLabel"}>
                      {t("main_image")}
                    </label>
                    <FileUpload id={'imageGame'} imageUrl={form.imageGame} form={form} setForm={setForm}></FileUpload>
                  </div>
                </div>
                <div>
                    <div className="prInput"  style={{ flex: "0 0 48%", margin:'0' }}>
                        <label htmlFor="game_desc" className={errors.game_desc ? "prInputLabel inputError" : "prInputLabel"}>
                        {t("game_desc")}
                        </label>
                    </div>
                    <Editor
                        id="body"
                        url={"content-pages"}
                        onChange={changeHandler}
                        maxLength={100000}
                        onBlur={customSave}
                        value={form.description}
                    />
                </div>
    
                <div className="dFlex" style={{gap:'15px',alignItems:'center',justifyContent:'center', marginTop:'50px'}}>
                    <button className="prButton prButtonSecond"  onClick={deleteClick}>Видалити</button>
                    {form.isActive==1 && <button className="prButton prButtonSecond"  onClick={deactivateClick}>Деактивувати</button>}
                    <button className="prButton prButtonSecond" onClick={cancelCLick}>Відхилити</button>
                    <button className="prButton prButtonMain" onClick={savedClick}>Зберегти</button>
                    {form.isActive==0 &&<button className='prButton prButtonMain' onClick={publicClick}>Опублікувати</button>}
    
                </div>
            </div>
          </div>
        </>
      );
}else{
    return(
        <></>
    )
}
 
};

export default GameEditPage;
