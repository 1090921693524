import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const register = (form) => {
  return axios.post(constants.URL + "auth/register", 
    {...form}
  ).then((response) => {
       
    return response;
  }).catch((e)=>{
      Promise.reject(e.response.data);
  });
};

const login = (body) => {
  return axios
    .post(constants.URL + "auth/login", {
      ...body
    })
    .then((response) => {
      console.log(response);
      if (response.data.accessToken && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      return Promise.reject(e.response.data);
    });
};

const restore = (form) => {
  return axios.post(constants.URL + "auth/restore", 
    {...form}
  ).then((response) => {
       
    return response;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
};


const registerCode=(body)=>{
  return axios
  .post(constants.URL + "auth/register-code",{...body})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    Promise.reject(e.response.data);
  });
}
const restoreCode=(body)=>{
  return axios
  .post(constants.URL + "auth/restore-code",{...body})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    Promise.reject(e.response.data);
  });
}

const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const refresh = (refreshToken) => {
  return axios.post(constants.URL +'auth/refresh', { refreshToken })
      .then(response => {
          return response;
      })
      .catch(error => {
          // Handle network or server errors
          console.error('Refresh token service error:', error); // Log the error for debugging
          return Promise.reject(error);
      });
};

export default {
  register,
  login,
  logout,
  registerCode,
  refresh,
  restoreCode,
  restore
};






