import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';
import { socket } from "../../socket";


const createRoomOld=(body)=>{
  return axios
  .post(constants.URL + "rooms/add-rooms",{...body},{ headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return e.response.data;
  });
}

const getRoomDetail=(body)=>{
  return axios
  .get(constants.URL + "rooms/"+body.id,{ headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return e.response.data;
  });
}

const createRoom = (body) => {
  return new Promise((resolve, reject) => {
    socket.emit('addRoom', body, (response) => {
      debugger
      if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  });
};
const getRoomsList = (body) => {
  return new Promise((resolve, reject) => {
    socket.emit('getRooms', body, (response) => {
      if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  });
};

const deleteRoom = (body) => {
  return new Promise((resolve, reject) => {
    socket.emit('deleteRoom', body, (response) => {
      if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  });
};
const joinRoom = (body) => {
  return new Promise((resolve, reject) => {
    socket.emit('roomJoin', body, (response) => {
      if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  });
};
const leaveRoom = (body) => {
  return new Promise((resolve, reject) => {
    socket.emit('roomLeave', body, (response) => {
      if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  });
};

export default {
  createRoom,
  createRoomOld,
  deleteRoom,
  getRoomDetail,
  joinRoom,
  leaveRoom,
  getRoomsList
};








