import {
    UPLOADFILE_SUCCESS,
    UPLOADFILE_FAIL,
    SET_MESSAGE,
  } from "./types";
  
  import FileService from "../services/file.service";
  
  export const uploadFile = (data, url) => (dispatch) => {
    return FileService.uploadFile(data, url).then(
      (response) => {
  
        dispatch({
          type: UPLOADFILE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response,
        });
        return response;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPLOADFILE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }