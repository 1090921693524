import {
    CREATEROOM_SUCCESS,
    CREATEROOM_FAIL,
    GETROOMLIST_SUCCESS,
    GETROOMLIST_FAIL,
    GETROOMDETAIL_SUCCESS,
    GETROOMDETAIL_FAIL,
    SETROOMLIST_SUCCESS,
    DELETEROOM_FAIL,
    DELETEROOM_SUCCESS,
    JOINROOM_FAIL,
    JOINROOM_SUCCESS,
    LEAVEROOM_FAIL,
    LEAVEROOM_SUCCESS,
    ERROR
  } from "./types";
  
  import RoomService from "../services/room.service";
  
  export const createRoom = (form) => (dispatch) => {
    return RoomService.createRoom(form).then(
      (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: CREATEROOM_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: CREATEROOM_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: CREATEROOM_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
  };

  export const getRoomsList = (form) => (dispatch) => {
    return RoomService.getRoomsList(form).then(
      (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: CREATEROOM_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: SETROOMLIST_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: CREATEROOM_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );

  };

  export const setRoomList = (form) => (dispatch) => {

          dispatch({
            type: SETROOMLIST_SUCCESS,
            payload: form,
          });
        return form;
    }


    export const  deleteRoom =(form)=>(dispatch) => {
        return RoomService.deleteRoom(form).then(
            (response) => {
              if(response.statusText!="OK" || response.data.errors){
                dispatch({
                  type: DELETEROOM_FAIL,
                  payload: response.data,
                });
              return response.data;
              }else{
                dispatch({
                  type: DELETEROOM_SUCCESS,
                  payload: response.data,
                });
                return  response.data;
              }
            },
            (error) => {
              dispatch({
                type: DELETEROOM_FAIL,
              });
              dispatch({
                type: ERROR,
                message:error.message
            });
            console.log(error);
              return Promise.reject();
            }
          );
    }

    export const  joinRoom =(form)=>(dispatch) => {
        return RoomService.joinRoom(form).then(
            (response) => {
              if(response.statusText!="OK" || response.data.errors){
                dispatch({
                  type: JOINROOM_FAIL,
                  payload: response.data,
                });
              return response.data;
              }else{
                dispatch({
                  type: JOINROOM_SUCCESS,
                  payload: response.data,
                });
                return  response.data;
              }
            },
            (error) => {
              dispatch({
                type: JOINROOM_FAIL,
              });
              dispatch({
                type: ERROR,
                message:error.message
            });
            console.log(error);
              return Promise.reject();
            }
          );
    }
    export const  leaveRoom =(form)=>(dispatch) => {
        return RoomService.leaveRoom(form).then(
            (response) => {
              if(response.statusText!="OK" || response.data.errors){
                dispatch({
                  type: LEAVEROOM_FAIL,
                  payload: response.data,
                });
              return response.data;
              }else{
                dispatch({
                  type: LEAVEROOM_SUCCESS,
                  payload: response.data,
                });
                return  response.data;
              }
            },
            (error) => {
              dispatch({
                type: LEAVEROOM_FAIL,
              });
              dispatch({
                type: ERROR,
                message:error.message
            });
            console.log(error);
              return Promise.reject();
            }
          );
    }


    

    export const getRoomDetail =(form)=>(dispatch)=>{
        return RoomService.getRoomDetail(form).then( (response) => {
            if(response.statusText!="OK" || response.data.errors){
              dispatch({
                type: GETROOMDETAIL_FAIL,
                payload: response.data,
              });
            return response.data;
            }else{
              dispatch({
                type: GETROOMDETAIL_SUCCESS,
                payload: response.data,
              });
              return  response.data;
            }
          },
          (error) => {
            dispatch({
              type: GETROOMDETAIL_FAIL,
            });
            dispatch({
              type: ERROR,
              message:error.message
          });
          console.log(error);
            return Promise.reject();
          }
        );
    }

  

  
  
  