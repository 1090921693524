import React from 'react';
import ReactDOM from 'react-dom/client'; // Use createRoot for React 18
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Assuming i18n is configured elsewhere
import { LanguageProvider } from './LanguageContext'; // Import custom context

const root = ReactDOM.createRoot(document.getElementById('root')); // Use createRoot

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <LanguageProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </LanguageProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

