import axios from "axios";

import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const uploadFile = (data, url) => {

    const curreltLink = url || `uploads`;

    let auth = authHeader();
    return axios({
        method: "post",
        url: constants.URL + curreltLink,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': auth.Authorization
        },
    })

}


export default {
    uploadFile,
}