import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { editUser } from "../../../redux/actions/user";

const ProfileSettingsPage =()=>{
    const { t } = useTranslation();
    const [form,setForm]=useState({});
    const [edit,setEdit]=useState(false);
    const [errors, setErrors] = useState({});
    const [showRepeat, setShowRepeat] = useState(false);
    const [show, setShow] = useState(false);
    const [showOld, setShowOld] = useState(false);
    const fullList = useSelector((state) => state.fullList);
    const list = useSelector((state) => state.list);

    const user = useSelector((state) => state.currentUser?.user);
    const [validForm, setValidForm] = useState({});
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);

    const changeHandler = (event) => {
        const { id, value } = event.target;
        if (event.target.tagName === 'SELECT' && event.target.accessKey) {
            setForm({ ...form, [event.target.accessKey]: value });
        } else {
            setForm({ ...form, [id]: value });
        }
    };
    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.password) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };

    const declineClick = () => {
        setEdit(false);
    };

    const initFunc = () => {
        console.log(user);
        setForm({...user});
    };

    const saveClick = () => {
        let data = { ...form };
        dispatch(editUser(data)).then(res => {
            setEdit(false);
            setForm({...res});
        }).catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready]);

    return(
        <>
         <div className="profileSectionBlock prFlexRow">
             <div style={{flex:'0 0 32%'}}>
                <div className="userEditLeft" style={{marginBottom:'20px'}}>
                    <h2>{t('user_info')}
                        <div className="infoHelp"><Icon type='info' /></div>
                    </h2>
                    {!edit && <div className="editButton" onClick={()=>setEdit(true)}><Icon type='edit' /></div>}
                    {edit?<div className="prInput">
                            <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                            <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                            {errors.nickname && <div className="error">{errors.nickname}</div>}
                        </div>:
                        <div className="prInput">
                            <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                            <div className="noEditField">{user?.nickname}</div>
                        </div>
                    }
                    {edit?<div className="prInput">
                            <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                            <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                            {errors.email && <div className="error">{errors.email}</div>}
                        </div>:
                        <div className="prInput">
                            <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                            <div className="noEditField">{user?.email}</div>
                        </div>
                    }
                    {edit?<div className="prInput">
                        <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                        <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                        {errors.birthday && <div className="error">{errors.birthday}</div>}
                    </div>:
                        <div className="prInput">
                            <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                        <div className="noEditField">{user?.birthday|| '---'}</div>
                    </div>
                    }
                    {edit?<div className="prInput">
                        <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                        <select
                            className={errors.sex ? "prInputInput inputError" : "prInputInput"}
                            id="sex"
                            value={form.sex || ""}
                            onChange={changeHandler}
                        >
                            <option value="">{t('empty_select')}</option>
                            {fullList.Sex.length > 0 &&
                                fullList.Sex.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                            }
                        </select>
                        {errors.sex && <div className="error">{errors.sex}</div>}
                    </div>:
                    <div className="prInput">
                        <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                        <div className="noEditField">{(user?.sex && list[user?.sex])|| '---'}</div>
                    </div>
                    }
                    {edit?<div className="prInput">
                        <label htmlFor="first_name" className={errors.first_name ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                        <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="first_name" type="text" className={errors.first_name ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                        {errors.first_name && <div className="error">{errors.first_name}</div>}
                    </div>:
                        <div className="prInput">
                            <label htmlFor="first_name" className={errors.first_name ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                            <div className="noEditField">{user?.first_name|| '---'}</div>
                        </div>
                    }
                    {edit?<div className="prInput">
                        <label htmlFor="last_name" className={errors.last_name ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                        <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="last_name" type="text" className={errors.last_name ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                        {errors.last_name && <div className="error">{errors.last_name}</div>}
                    </div>:
                        <div className="prInput">
                        <label htmlFor="last_name" className={errors.last_name ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                        <div className="noEditField">{user?.last_name|| '---'}</div>
                    </div>
                    }
                    {edit?<div className="prInput">
                        <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                        <select
                            className={errors.country ? "prInputInput inputError" : "prInputInput"}
                            id="country"
                            value={form.country || ""}
                            onChange={changeHandler}
                        >
                            <option value="">{t('empty_select')}</option>
                            <option value="Україна">Україна</option>
                            <option value="Інша країна">Інша країна</option>
                        </select>
                        {errors.country && <div className="error">{errors.country}</div>}
                    </div>:
                        <div className="prInput">
                        <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                        <div className="noEditField">{user?.country|| '---'}</div>
                    </div>
                    }
                    {edit?<div className="prInput">
                        <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                        <select
                            className={errors.time_line ? "prInputInput inputError" : "prInputInput"}
                            id="time_line"
                            value={form.time_line || ""}
                            onChange={changeHandler}
                        >
                            <option value="">{t('empty_select')}</option>
                            <option value="+1">+1</option>
                            <option value="+2">+2</option>
                        </select>
                        {errors.time_line && <div className="error">{errors.time_line}</div>}
                    </div>:
                        <div className="prInput">
                        <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                        <div className="noEditField">{user?.time_line|| '---'}</div>
                    </div>
                    }
                {edit&&<div className="userEditButtons">
                        <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                        <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
                    </div>}
                </div>      
                <div className="userEditLeft">
                    <h2>{t('change_password')}
                        <div className="infoHelp"><Icon type='info' /></div>
                    </h2>
                    <div className="prInput prInputIcon">
                        <label htmlFor="oldPassowrd" className={errors.oldPassowrd ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                        <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="oldPassowrd" type={showOld ? 'text' : "password"} className={errors.oldPassowrd ? "prInputInput inputError" : "prInputInput"} placeholder={t("old_password")} />
                        <div className="prInputIconIcon" onClick={() => setShowOld(!showOld)}><Icon type={showOld ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.oldPassowrd && <div className="error">{errors.oldPassowrd}</div>}
                    </div>
                    <div className="prInput prInputIcon">
                        <label htmlFor="password" className={errors.password ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                        <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="password" type={show ? 'text' : "password"} className={errors.password ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                        <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <div className="prInput prInputIcon">
                        <label htmlFor="passwordRepeat" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                        <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeat" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                        <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                    </div>
                    <button style={{margin:'13px 0 13px 47px'}} className="prButton prButtonMain">{t('save_password')}</button>
                </div>
             </div>
           
            <div className="userEditLeft">
                <h2>{t('reputation')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h2>

                <div className="dFlex jCC" style={{gap:'24px'}}>
                    <div className={(user?.reputation>7 && "circleReputationSettings") || (user?.reputation<7 && user?.reputation<2 && "circleReputationSettings orange") ||(user?.reputation<3 && "circleReputationSettings red")}>
                        {user.reputation}
                    </div>
                    <div className="reputationRightText">
                        Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                    </div>
                </div>

                <h4 className="reputationH4">{t('reputation')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h4>
                <div>
                    <div className="reputationListItem dFlex green" style={{gap:'20px'}}>
                        <div className="circleReputationSettingsSmall">
                            +
                        </div>
                        <div>
                            <div className="reputationListItemValue">
                                +1 бал
                            </div>
                            <div className="reputationListItemRemark">
                                Дякуємо за вашу активність
                            </div>
                        </div>
                    </div>
                    <div className="reputationListItem dFlex red" style={{gap:'20px'}}>
                        <div className="circleReputationSettingsSmall">
                            -
                        </div>
                        <div>
                            <div className="reputationListItemValue">
                                -1 бал
                            </div>
                            <div className="reputationListItemRemark">
                                Причина знятта балу: гравець покинув кімнату 3 рази поспіль.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
            <div className="userEditLeft" style={{marginBottom:'20px'}}>
                <h2>{t('personal_info')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h2>
                <div className="simpleTextPersonal mb">
                    {getDate(new Date(),'dd/mm/yyyy','.')}
                </div>
                <div className="simpleHeaderPersonal">
                    {t("last_activity")}
                </div>
                <div className="simpleTextPersonal mb">
                    {getDate(new Date(),'dd/mm/yyyy','.')}
                </div>
                <div className="simpleHeaderPersonal" style={{marginBottom:'11px'}}>
                    {t("files_for_verification")}
                </div>
                <div className="simpleTextPersonal mb dFlex" style={{'gap':'7px'}}>
                    <Icon type='files'></Icon>
                    <Icon type='files'></Icon>
                    <Icon type='files'></Icon>

                </div>
                <div className="simpleHeaderPersonal">
                    {t("last_ips")}
                </div>
                <div className="simpleTextPersonal">
                    000.000.000.0
                </div>
                <div className="simpleTextPersonal">
                    000.000.000.0
                </div>
            </div>
            <div className="userEditLeft">
                <h2>{t('delete_personal_info')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h2>
                <div className="simpleTextPersonal mb">
                    {t('delete_1')}
                </div>
                <div className="simpleTextPersonal mb">
                    {t('delete_2')}
                </div>
                <button className="prButton prButtonMain" style={{margin:"20px"}}>
                    {t('delete_personal_button')}
                </button>
            </div>
            </div>
            
        </div>
        </>
    )

}

export default ProfileSettingsPage;