import {
    GETGAME_SUCCESS,
    GETGAME_FAIL,
    EDITGAME_SUCCESS,
    EDITGAME_FAIL,
    DELETEGAME_SUCCESS,
    DELETEGAME_FAIL,
    GETGAMEITEM_SUCCESS,
    GETGAMEITEM_FAIL,
    CREATEGAME_SUCCESS,
    CREATEGAME_FAIL,
    ADDNICKNAME,
    GETMYGAME_SUCCESS,
    GETMYGAME_FAIL
} from "./types";

import GameService from "../services/game.service"

export const getGames = (data)=> async(dispatch)=>{ 
    try{
        const res = await GameService.getGames(data);
        dispatch({
          type: GETGAME_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETGAME_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
export const getMyGames = (data)=> async(dispatch)=>{ 
    try{
        const res = await GameService.getMyGames(data);
        dispatch({
          type: GETMYGAME_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETMYGAME_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}



export const editGame= (form)=>(dispatch)=>{
      
    return GameService.editGame(form).then(
        (response)=>{
            dispatch({
                type:EDITGAME_SUCCESS,
                item:response
            });
            return response
        },
        (error)=>{
            dispatch({
                type: EDITGAME_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const deleteGame= (data)=>(dispatch)=>{
      
    return GameService.deleteGame(data).then(
        (response)=>{
            dispatch({
                type:DELETEGAME_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEGAME_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getGameItem = (data)=>(dispatch)=>{
    return GameService.getGameItem(data).then(
        (response)=>{
            dispatch({
                type:GETGAMEITEM_SUCCESS,
                item:response,
            });
            return response
        },
        (error)=>{
            dispatch({
                type: GETGAMEITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}

export const addGame = (data)=> async(dispatch)=>{
    try {
        const res = await GameService.addGame(data);
        dispatch({
          type: CREATEGAME_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEGAME_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}

export const addNickname =(form)=>(dispatch)=>{
    return GameService.addNickname(form).then( (response) => {
          dispatch({
            type: ADDNICKNAME,
          });
          return  response;
      },
      (error) => {
        dispatch({
            type: CREATEGAME_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
      }
    );
  }
  