import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";  
import { Icon } from "../Icon";
import DatePicker from "../DatePicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import getDate from "../../helpers/getDate";
import { getUserReputation } from "../../redux/actions/user";
import TeamResult from "../TeamResult/TeamResult";

const Filter=(props)=>{
    const { t } = useTranslation();
    const [form,setForm]=useState({})
    const [temperary,setTemperary]=useState({})
    const fullList = useSelector((state) => state.fullList);
    const user = useSelector((state) => state.currentUser.user);
    const list = useSelector((state) => state.list);
    const [activeTeamA, setActiveTeamA] = useState('');
    const [activeTeamB, setActiveTeamB] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if(props.type=='leaveRoom'){
            dispatch(getUserReputation({roomId:props.game.id})).then(res=>{
                console.log(res);
                setTemperary(res);
            })
        }
    }, []);
    
   

    const [checkedC, setCheckedC] = useState(false);

    const toggleSidebar = () => {
        props.setIsOpen(!props.isOpen);
    };
  
    const closeSidebar = (e) => {
      if (e.target.classList.contains("overlay")) {
        props.setIsOpen(false);
      }
    };
    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
        props.onChange(event);
    };


    useEffect(() => {
        setForm({ ...form, 'checkedC':checkedC });
        if(props.needCheckedC){
            props.onChange({target:{id:'checkedC' , value:checkedC}});
        }
    }, [checkedC]);

    const acceptClick=()=>{
        props.savefilter();
        props.accept();
        toggleSidebar();
    }
    const acceptClickNoFilter=()=>{
        props.accept();
        toggleSidebar();
    }

    const handleIconClick = (team, resultType) => {
        if (team === 'teamA') {
            setActiveTeamA(resultType);
            if (resultType === 'win') {
                props.onChange({},{ teamA: '2', teamB: '0' })
                setActiveTeamB('lose');
            } else if (resultType === 'draft') {
                props.onChange({},{ teamA: '1', teamB: '1' })
                setActiveTeamB('draft');
            } else if (resultType === 'lose') {
                props.onChange({},{ teamA: '0', teamB: '2' })
                setActiveTeamB('win');
            }
        } else if (team === 'teamB') {
            setActiveTeamB(resultType);
            if (resultType === 'win') {
                props.onChange({},{ teamA: '0', teamB: '2' })
                setActiveTeamA('lose');
            } else if (resultType === 'draft') {
                props.onChange({},{ teamA: '1', teamB: '1' })
                setActiveTeamA('draft');
            } else if (resultType === 'lose') {
                props.onChange({},{ teamA: '2', teamB: '0' })
                setActiveTeamA('win');
            }
        }
    };

    if(props.type=='users'){
        return(
            <>
                
                <div className="overlay" onClick={closeSidebar}>
                  <div className="sidebar">
                    <h3 className="prFlexRow">
                        <div className="sidebarFilter">
                            <Icon type='filter'></Icon> 
                            <div>{t('filter_header')}</div>
                        </div> 
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                    <div className="sidebarBody">
                    <div className="prInput">
                        <label htmlFor="all" className={"prInputLabel"}>{t("search_name_nick_enail")}</label>
                        <input onChange={changeHandler} value={props.filter.all||form.all} id="all" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                    </div>
                    <div className="prInput">
                        <label htmlFor="role" className={"prInputLabel"}>{t("role_system")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="role"
                                    value={props.filter.role || ""}
                                    onChange={changeHandler}
                                >
                                <option value="">{t('empty_select')}</option>
                                {fullList.UserRoles.length > 0 &&
                                    fullList.UserRoles.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                                }
                            </select>
                    </div>
                    <div className="prInputPeriod">
                        <label htmlFor="birthday_period" className={"prInputLabel"}>{t("birthday_period")}</label>
                        <div className="prFlexRow">
                            <div><DatePicker onChange={changeHandler} id="dataBirthdayFrom" value={(props.filter.dataBirthdayFrom && getDate(props.filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} icon={true}></DatePicker></div>
                            <div className="prInputPeriodDevider">-</div>
                            <div><DatePicker onChange={changeHandler} id="dataBirthdayTo" value={(props.filter.dataBirthdayTo && getDate(props.filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''}icon={true}></DatePicker></div>
                        </div>
                    </div>
        
                    <div  className="prInput">
                        <label htmlFor="sex" className={"prInputLabel"}>{t("gender")}</label>
                        <select
                            className={"prInputInput"}
                            id="sex"
                            value={props.filter.sex || ""}
                            onChange={changeHandler}
                        >
                            <option value="">{t('empty_select')}</option>
                            {fullList.Sex.length > 0 &&
                                fullList.Sex.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div  className="prInput">
                        <label htmlFor="game" className={"prInputLabel"}>{t("game")}</label>
                        <select disabled className={"prInputInput"} name="" id="">
                            <option value="">{t('empty_select')}</option>
                            <option value="">CS</option>
                            <option value="">Dota</option>
                            <option value="">WoT</option>
                        </select>
                    </div>
                    <div className="prInputPeriod">
                        <label htmlFor="reputationFrom" className={"prInputLabel"}>{t("reputation")}</label>
                        <div className="prFlexRow">
                            <select className={"prInputInput"}  id="reputationFrom"
                                            value={props.filter?.reputationFrom || ""}
                                            onChange={changeHandler}
                                            >
                                <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                            <div className="prInputPeriodDevider">-</div>
                            <select className={"prInputInput"}  id="reputationTo"
                                            value={props.filter?.reputationTo || ""}
                                            onChange={changeHandler}
                                            >
                                <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                        </div>
                    </div>
                    <div  className="prInput">
                        <label htmlFor="status" className={"prInputLabel"}>{t("status")}</label>
                        <select disabled className={"prInputInput"} name="" id="">
                            <option value="">{t('empty_select')}</option>
                            <option value="">Верифіковано</option>
                            <option value="">Очікує</option>
                            <option value="">Не верифіковано</option>
                        </select>
                    </div>
                        <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                            <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                        </div>
                        <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                    </div>
                  </div>
                </div>    
            </>)
    }
    else if (props.type=='rooms'){
    return(
<>
                <div className="overlay" onClick={closeSidebar}>
                  <div className="sidebar">
                    <h3 className="prFlexRow">
                        <div className="sidebarFilter">
                            <Icon type='filter'></Icon> 
                            <div>{t('filter_header')}</div>
                        </div> 
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                    <div className="sidebarBody">
                    <div className="prInput">
                        <label htmlFor="roomId" className={"prInputLabel"}>{t("search_room_id")}</label>
                        <input onChange={changeHandler} id="roomId" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                    </div>
                    <div className="prInputPeriod">
                        <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                        <div className="prFlexRow">
                        <input onChange={changeHandler} id="roomId" type="text" className={"prInputInput"} placeholder={'0$'} />
                        <div className="prInputPeriodDevider">-</div>
                        <input onChange={changeHandler} id="roomId" type="text" className={"prInputInput"} placeholder={'10000$'} />
                        </div>
                    </div>
                    <div className="prInput">
                        <label htmlFor="tockenType" className={"prInputLabel"}>{t("tocken_type")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="tockenType"
                                    value={props.filter.tockenType || ""}
                                    onChange={changeHandler}
                                >
                                <option value="">{t('empty_select')}</option>
                                {fullList.TypeToken.length > 0 &&
                                    fullList.TypeToken.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                                }
                               
                            </select>
                    </div>
                    <div className="prInput">
                        <label htmlFor="roleRoom" className={"prInputLabel"}>{t("role_room")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="roleRoom"
                                    value={props.filter.roleRoom || ""}
                                    onChange={changeHandler}
                                >
                                <option value="">{t('empty_select')}</option>
                                {fullList.UserStatus.length > 0 &&
                                    fullList.UserStatus.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                                }
                            </select>
                    </div>
                    <div className="prInput">
                        <label htmlFor="game" className={"prInputLabel"}>{t("game")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="game"
                                    value={props.filter.game || ""}
                                    onChange={changeHandler}
                                    disabled
                                >
                                <option value="">{t('empty_select')}</option>
                                <option value={'user'}>Dota 2</option>
                                <option value={'organisator'}>CS 2</option>
                                <option value={'captain'}>WoT</option>
                            </select>
                    </div>
                    <div className="prInput">

                        <label htmlFor="platform" className={"prInputLabel"}>{t("platform")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="platform"
                                    value={props.filter.platform || ""}
                                    onChange={changeHandler}
                                >
                                <option value="">{t('empty_select')}</option>
                                {fullList.Platform.length > 0 &&
                                    fullList.Platform.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                                }
                            </select>
                    </div>
                    <div className="prInput">
                        <label htmlFor="result" className={"prInputLabel"}>{t("result")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="result"
                                    value={props.filter.result || ""}
                                    onChange={changeHandler}
                                >
                                <option value="">{t('empty_select')}</option>
                                {fullList.ResultGame.length > 0 &&
                                    fullList.ResultGame.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                                }
                            </select>
                            
                    </div>
                        <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                            <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                        </div>
                        <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                    </div>
                  </div>
                </div>    
            </>)
    }
    else if (props.type=='transaction'){
        return(
    <>
                    <div className="overlay" onClick={closeSidebar}>
                      <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                        <div className="prInput">
                            <label htmlFor="transactionId" className={"prInputLabel"}>{t("search_id_transaction")}</label>
                            <input onChange={changeHandler} id="transactionId" type="text" value={props.filter.transactionId||''} className={"prInputInput"} placeholder={t("enter_value")} />
                        </div>
                        <div className="prInputPeriod">
                            <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                            <div className="prFlexRow">
                            <input onChange={changeHandler} id="transactionFrom" type="text" className={"prInputInput"} placeholder={'0$'} />
                            <div className="prInputPeriodDevider">-</div>
                            <input onChange={changeHandler} id="transactionTo" type="text" className={"prInputInput"} placeholder={'10000$'} />
                            </div>
                        </div>
                        <div className="prInput">
                            <label htmlFor="transactionType" className={"prInputLabel"}>{t("transaction_type")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="transactionType"
                                        value={props.filter.transactionType || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    <option value={'fiat'}>Поповнення</option>
                                    <option value={'crypto'}>Вивід</option>
                                </select>
                        </div>
                        <div className="prInput">
                            <label htmlFor="paymentOrg" className={"prInputLabel"}>{t("payment_org")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="paymentOrg"
                                        value={props.filter.paymentOrg || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    <option value={'fiat'}>PayPal</option>
                                    <option value={'crypto'}>Payever</option>
                                </select>
                        </div>
                        <div className="prInput">
                            <label htmlFor="transactionStatus" className={"prInputLabel"}>{t("transaction_status")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="transactionStatus"
                                        value={props.filter.transactionStatus || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    <option value={'fiat'}>Закрита</option>
                                    <option value={'crypto'}>Помилка</option>
                                    <option value={'crypto'}>Очікується</option>
                                </select>
                        </div>
                        <div className="prInput">
                            <label htmlFor="tockenType" className={"prInputLabel"}>{t("tocken_type")}</label>
                                <select
                                    className={ "prInputInput"}
                                        id="tockenType"
                                        value={props.filter.tockenType || ""}
                                        onChange={changeHandler}
                                    >
                                    <option value="">{t('empty_select')}</option>
                                    <option value={'fiat'}>Fiat tocken</option>
                                    <option value={'crypto'}>Crypto tocken</option>
                                </select>
                        </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                      </div>
                    </div>    
                </>)
        }



        else if (props.type=='createRooms'){
            return(
        <>
                        <div className="overlay" onClick={closeSidebar}>
                          <div className="sidebar bigSidebar">
                            <h3 className="prFlexRow">
                                <h2>{t('message')}
                                    <div className="infoHelp"><Icon type='info'/></div>
                                </h2>
                                <div className="prFlexRow sidebarFilterButtons"> 
                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                </div>
                            </h3>

                            <div className="simpleText">
                                Якщо ви створюєте кімнату вперше, прочитайте наші рекомендації
                                та поради у розділі “<a href="#">Створення ігрової кімнати</a>”
                            </div>
                            <div className="sidebarBody">
                            {props.game?
                            <div className="prInput">
                            <label htmlFor="game" className={"prInputLabel"}>{t("choose_game")}</label>
                                    <select
                                    className={ "prInputInput"}
                                        id="game"
                                        value={props.filter.game || ""}
                                        onChange={changeHandler}
                                        disabled={true}
                                    >
                                    <option value={props.game.id}>{props.game.name}</option>
                                </select>
                        </div>
                            :<div className="prInput">
                                <label htmlFor="game" className={"prInputLabel"}>{t("choose_game")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="game"
                                            value={props.filter.game || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        <option value={'user'}>Dota 2</option>
                                        <option value={'organisator'}>CS 2</option>
                                        <option value={'captain'}>WoT</option>
                                    </select>
                            </div>}

                            {/* <div className="prInput">
                                <label htmlFor="isPrivateRoom" className={"prInputLabel"}>{t("is_private_room")}</label>
                                <div className="">
                                    <div className="">Так</div>
                                    <div className="">Ні</div>
                                </div>
                            </div> */}


                            <div className="prInput">
                                <label htmlFor="cost_invite" className={"prInputLabel"}>{t("cost_invite")}</label>
                                <div className="prFlexRow">
                                    <input onChange={changeHandler} style={{width:'88px'}} id="price" type="text" value={props.filter.price||''} className={"prInputInput"} placeholder={''} />
                                    <div className="checkInput prFlexRow" style={{ width: '60px',marginLeft:"55px"}}>
                                        <div onClick={() => setCheckedC(false)} className={!checkedC ? "prCheckBox checked" : "prCheckBox"} >
                                        </div>
                                        <div style={{ textAlign: 'left', width: '310px' }} onClick={() => setCheckedC(false)}>
                                            <Icon type='fTocken'></Icon>
                                        </div>
                                        </div>
                                    <div className="checkInput prFlexRow" style={{ width: '60px',marginLeft:"25px"}}>
                                        <div onClick={() => setCheckedC(true)} className={checkedC ? "prCheckBox checked" : "prCheckBox"} >
                                        </div>
                                        <div style={{ textAlign: 'left', width: '310px' }} onClick={() => setCheckedC(true)}>
                                            <Icon type='cTocken'></Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="prInput">
                                    <div className="prInput" style={{ marginBottom: "-9px" }}>
                                        <label htmlFor="platform" className={"prInputLabel"}>
                                        {t("platform")}
                                        </label>
                                    </div>
                                    {fullList.Platform.map((el) => {
                                        if(props.game['platforms']?.includes(el.id)){
                                            return(
                                            <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                <div
                                                    id={el.id}
                                                    onClick={props.selectClick}
                                                    className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                ></div>
                                                <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                    {t(el.title)}
                                                </div>
                                            </div>
                                            )
                                        }
                                    })}
                            </div >
                            <div className="prInput">
                                <div className="prInput" style={{ marginBottom: "-9px" }}>
                                    <label htmlFor="format" className="prInputLabel">{t("platform")} для вашого пристрою</label>
                                    <select
                                        className="prInputInput"
                                        id="platformId"
                                        value={props.filter.platformId || ""}
                                        onChange={changeHandler}
                                    >
                                        <option value="">{t('empty_select')}</option>
                                        {user.gamesNickname.map((el) => {
                                            if (el.gameId === props.game.id) {
                                                return el.platforms.map((elem, index) => {
                                                    if (props.filter['platform']?.includes(elem.platform)) {
                                                        return (
                                                            <option key={index} value={elem.platform}>
                                                                {list[elem.platform]}
                                                            </option>
                                                        );
                                                    }
                                                    return null;
                                                });
                                            }
                                            return null;
                                        })}
                                    </select>
                                </div>
                            </div>
                                
                            <div className="prInput">

                                <label htmlFor="format" className={"prInputLabel"}>{t("format")}</label>
                                        <select
                                        className={ "prInputInput"}
                                            id="format"
                                            value={props.filter.format || ""}
                                            onChange={changeHandler}
                                        >
                                        <option value="">{t('empty_select')}</option>
                                        {props?.game?.formats?.length > 0 &&
                                            props?.game?.formats?.map(el => (
                                            <option key={el} value={el}>{el+' x '+el}</option>
                                        ))
                                        }
                                    </select>
                            </div>

                            <div className="prInput">

                                <label htmlFor="description" className={"prInputLabel"}>{t("description")}</label>
                                <div className="simpleText">
                                 Якщо у вас є певні умови, наприклад на якому сервері або за якими правилами будете грати, обов’язково напишіть про це в описі, щоб інші учасники орієнтувались
                                </div>
                                <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                                        
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                                <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>{t('next')}</button>
                            </div>
                            </div>
                          </div>
                        </div>    
                    </>)
            }
            else if (props.type=='addMyGame'){
                return(
            <>
                            <div className="overlay" onClick={closeSidebar}>
                              <div className="sidebar myGameEdit">
                                <h3 className="prFlexRow">
                                    <h2 className="chooseFor">
                                        {t('choose_options_for_game')} 
                                        <br />
                                        <span className="chooseForGame"> {props.filter.name}!</span>
                                    </h2>
                                    <div className="prFlexRow sidebarFilterButtons"> 
                                        <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                    </div>
                                </h3>

                                <div className="platform">
                                    <div className="prInput" style={{ marginBottom: "-9px" }}>
                                        <label htmlFor="platform" className={"prInputLabel"}>
                                        {t("platform")}
                                        </label>
                                    </div>
                                    {fullList.Platform.map((el) => {
                                        if(props.filter['platforms']?.includes(el.id)){
                                            return(
                                            <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                <div
                                                    id={el.id}
                                                    onClick={props.selectClick}
                                                    className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                ></div>
                                                <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                    {t(el.title)}
                                                </div>
                                            </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="platform">
                                    {props?.filter?.platform.map(el=>{

                                        return(
                                            <div className="prInput">
                                                <label htmlFor={el} className={"prInputLabel"}>{t("nickname_for")} {list[el]}</label>
                                                <input onChange={changeHandler} id={el} type="text" value={props?.filter?.platformNew ? props?.filter?.platformNew[el]:''} className={"prInputInput"} placeholder={t("enter_value")} />
                                            </div>
                                        )

                                    })}

                                </div>
                               
                                <div className="userEditButtons">
                                    <button className="prButton prButtonSecond" onClick={props.accept}>Зберегти</button>
                                </div>
                                </div>
                            </div>    
                        </>)
                }
                else if (props.type=='joinRoom'){
                    return(
                <>
                                <div className="overlay" onClick={closeSidebar}>
                                  <div className="sidebar bigSidebar">
                                    <h3 className="prFlexRow">
                                        <h2 className="chooseFor">
                                            {t('choose_options_for_game')} 
                                            <br />
                                            <span className="chooseForGame"> {props.game.game.name}!</span>
                                        </h2>
                                        <div className="prFlexRow sidebarFilterButtons"> 
                                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                        </div>
                                    </h3>
                                <div className="sidebarBody">

                                <div className="prInput" style={{ marginBottom: "20px" }}>
                                    <label htmlFor="format" className="prInputLabel">{t("platform")} для вашого пристрою</label>
                                    <select
                                        className="prInputInput"
                                        id="platformId"
                                        value={props.filter.platformId || ""}
                                        onChange={changeHandler}
                                    >
                                        <option value="">{t('empty_select')}</option>
                                        {user.gamesNickname.map((el) => {
                                            if (el.gameId === props.game.game.id) {
                                                return el.platforms.map((elem, index) => {
                                                    if (props.game['platform']?.includes(elem.platform)) {
                                                        return (
                                                            <option key={index} value={elem.platform}>
                                                                {list[elem.platform]}
                                                            </option>
                                                        );
                                                    }
                                                    return null;
                                                });
                                            }
                                            return null;
                                        })}
                                    </select>
                                </div>
                                <div className="userEditButtons">
                                        <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                                        <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>{t('next')}</button>
                                    </div>
                                    </div>
                                </div>    
                                </div>    
                            </>)
                    }
                    else if (props.type=='leaveRoom'){
                        return(
                    <>
                                    <div className="overlay" onClick={closeSidebar}>
                                      <div className="sidebar bigSidebar">
                                        <h3 className="prFlexRow">
                                            <h2 className="">
                                                Ви точно хочете покинути кімнату? 
                                                {/* <span className="chooseForGame"> {props.game.game.name}!</span> */}
                                            </h2>
                                            <div className="prFlexRow sidebarFilterButtons"> 
                                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                            </div>
                                        </h3>
                                    <div className="sidebarBody">
                                        <div className="attentionPart dFlex">
                                            <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                                            <div>
                                                <h3>Увага!</h3>
                                                <div>Нагадуємо що:</div>
                                                <div>За 3 виходи з Кімнати поспіль з вашої репутації буде списано 1 бал</div>
                                                <div>За 5 виходів з Кімнати впродовж доби з вашої репутації буде списано 2 бали</div>
                                            </div>
                                        </div>

                                        <div className="leavingRoomText">
                                            <h3>Зараз у вас:</h3>
                                            <div> {temperary?.consecutiveExits} із 3 виходів із кімнати поспіль</div>
                                            <div> {temperary?.dayExit} із 5 виходи із кімнати впродовж доби</div>
                                        </div>

                                    <div className="userEditButtons">
                                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                                            <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>{t('next')}</button>
                                        </div>
                                        </div>
                                    </div>    
                                    </div>    
                                </>)
                        }
                        else if (props.type=='callAdmin'){
                            return(
                        <>
                                        <div className="overlay" onClick={closeSidebar}>
                                          <div className="sidebar bigSidebar">
                                            <h3 className="prFlexRow">
                                                <h2 className="">
                                                    Форма виклику адміністратора
                                                    {/* <span className="chooseForGame"> {props.game.game.name}!</span> */}
                                                </h2>
                                                <div className="prFlexRow sidebarFilterButtons"> 
                                                    <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                                </div>
                                            </h3>
                                        <div className="sidebarBody">
                                           <div className="prInput">
                                                <div className="simpleText">
                                                Опишіть, будь ласка, суть своєї скарги
                                                </div>
                                                <textarea onChange={changeHandler} id="description" maxLength={250} value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                                                <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                                            </div>
                                            <div className="attentionPart dFlex">
                                                <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                                                <div>
                                                    <h3>Увага!</h3>
                                                    <div>Безпричинний виклик адміністратора може призвести до зняття репутаційних балів</div>
                                                </div>
                                            </div>
                                             <div className="userEditButtons" style={{marginTop:'40px'}}>
                                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                                <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Поскаржитись</button>
                                            </div>
                                            </div>
                                        </div>    
                                        </div>    
                                    </>)
                            }else if(props.type=='callRef'){
                                return(
                                    <> 
                                    <div className="overlay" onClick={closeSidebar}>
                                        <div className="sidebar bigSidebar">
                                        <h3 className="prFlexRow">
                                            <h2 className="">
                                                Завантажити файли із доказами?
                                            </h2>
                                            <div className="prFlexRow sidebarFilterButtons"> 
                                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                            </div>
                                        </h3>
                                    <div className="sidebarBody">
                                        <div className="prInput">
                                            <div className="simpleText">
                                                Виклик арбітра потрібен лише тоді, коли один із учасників не погоджується із результатами, або підозрює іншого учасника в неспортивній поведінці (шахрайство, читерство тощо).  Цю дію неможливо скасувати чи відредагувати, тому будьте уважні, заповнюючи цю форму
                                            </div>
                                        </div>

                                        <div className="prInput">
                                                <label className="prInputLabel">
                                                    Опишіть причину виклику арбітра
                                                </label>
                                                <div className="simpleText">
                                                    Опишіть, будь ласка, суть своєї скарги
                                                </div>
                                                <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                                                <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                                        </div>

                                        <div>
                                            <TeamResult handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                                        </div>

                                        <div className="switch-container prInput" style={{marginTop:'40px'}}>
                                            <label className="prInputLabel">Чи користувались Ви під час гри клієнтом?</label>
                                            <label className="switch">
                                                <input
                                                type="checkbox"
                                                id="isClientUsed"
                                                checked={props.filter.isClientUsed}
                                                onChange={changeHandler}
                                                />
                                                <span className="slider round"></span>
                                                {props.filter.isClientUsed==1?<span className="switchText left">Так</span>:
                                                <span className="switchText right">Ні</span>}
                                            </label>
                                        </div>

                                        
                                        <div className="prInput">
                                            <div className="simpleText" style={{marginBottom:'11px'}}>
                                                Якщо хочете надати нам більше інформації, можете розмістити її на Dropbox чи Google Drive, відкрити доступ до перегляду, і надіслати нам посилання
                                            </div>
                                            <input onChange={changeHandler} value={props.filter.link||form.link} id="link" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                                        </div>
                                        <div className="attentionPart dFlex">
                                            <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                                            <div>
                                                <h3>Увага!</h3>
                                                <div>Безпричинний виклик арбітра може призвести до зняття репутаційних балів</div>
                                            </div>
                                        </div>
                                        <div className="userEditButtons" style={{marginTop:'40px'}}>
                                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                            <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Надіслати</button>
                                        </div>
                                        </div>
                                    </div>    
                                  </div>   

                                    </>
                                )
                            }
                            else if(props.type=='callRefAdd'){
                                return(
                                    <> 
                                    <div className="overlay" onClick={closeSidebar}>
                                        <div className="sidebar bigSidebar">
                                        <h3 className="prFlexRow">
                                            <h2 className="">
                                                Завантажити файли із доказами?
                                            </h2>
                                            <div className="prFlexRow sidebarFilterButtons"> 
                                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                            </div>
                                        </h3>
                                    <div className="sidebarBody">
                                        <div className="switch-container prInput">
                                            <label className="prInputLabel">Чи користувались Ви під час гри клієнтом?</label>
                                            <label className="switch">
                                                <input
                                                type="checkbox"
                                                id="isClientUsed"
                                                checked={props.filter.isClientUsed==1}
                                                onChange={changeHandler}
                                                />
                                                <span className="slider round"></span>
                                                {props.filter.isClientUsed==1?<span className="switchText left">Так</span>:
                                                <span className="switchText right">Ні</span>}
                                            </label>
                                        </div>
                                        <div className="prInput">
                                            <div className="simpleText" style={{marginBottom:'11px'}}>
                                                Якщо хочете надати нам більше інформації, можете розмістити її на Dropbox чи Google Drive, відкрити доступ до перегляду, і надіслати нам посилання
                                            </div>
                                            <input onChange={changeHandler} value={props.filter.link||form.link} id="link" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                                        </div>

                                        <div className="prInput">
                                            <label className="prInputLabel">Ваш суперник до виклику арбітра додав наступний текст:</label>
                                            <div className="infoWithPrev">
                                                {props.game.appealArbitrator[0].description}
                                            </div>
                                        </div>
                                        <div className="prInput">
                                                <label className="prInputLabel">
                                                    Напишіть свою відповідь (за бажання)
                                                </label>
                                                <div className="simpleText">
                                                    Зерніть увагу, Ваш суперник буде бачити текст, написаний к цьому полі, тому радимо бути уважними та обережними у формулюваннях
                                                </div>
                                                <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                                                <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                                        </div>
                                        <div className="attentionPart dFlex">
                                            <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                                            <div>
                                                <h3>Увага!</h3>
                                                <div>Нагадуємо, що підробка доказів та введення в оману може призвети до втрати балів репутації та навіть бану</div>
                                            </div>
                                        </div>
                                        <div className="userEditButtons" style={{marginTop:'40px'}}>
                                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                            <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Надіслати</button>
                                        </div>
                                        </div>
                                    </div>    
                                  </div>   

                                    </>
                                )
                            }
}

export default Filter;