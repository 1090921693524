import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';



const getAllDictionaries=(body)=>{
  return axios
  .get(constants.URL + "dictionaries",{ headers: authHeader()})
  .then((response) => {
    return response.data;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
}

const getDictionaries=(body)=>{
    return axios
    .get(constants.URL + "dictionaries/"+body.type,{ headers: authHeader()})
    .then((response) => {
      return response;
    }).catch((e)=>{    
      return  Promise.reject(e.response.data);
    });
  }

  const addDictionaries=(body)=>{
    return axios
    .post(constants.URL + "dictionaries/"+body.type,body,{ headers: authHeader()})
    .then((response) => {
      return response;
    }).catch((e)=>{    
      return  Promise.reject(e.response.data);
    });
  }
  const editDictionaries=(body)=>{
    return axios
    .put(constants.URL + "dictionaries/"+body.type+'/'+body.id,body,{ headers: authHeader()})
    .then((response) => {
      return response;
    }).catch((e)=>{    
      return Promise.reject(e.response.data);
    });
  }
  const getDictionariesOne=(body)=>{
    return axios
    .get(constants.URL + "dictionaries/"+body.type+'/'+body.id,{ headers: authHeader()})
    .then((response) => {
      return response;
    }).catch((e)=>{    
      return e.response.data;
    });
  }
  const deleteDictionaries=(body)=>{
    return axios
    .delete(constants.URL + "dictionaries/"+body.type+'/'+body.id,{ headers: authHeader()})
    .then((response) => {
      return response;
    }).catch((e)=>{    
      return  Promise.reject(e.response.data);
    });
  }


export default {
    getAllDictionaries,
    getDictionaries,
    addDictionaries,
    editDictionaries,
    getDictionariesOne,
    deleteDictionaries
};








