import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const ProfileWalletPage=()=>{
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [sort,setSort]=useState('asc');
    const [ready, setReady] = useState(false);
    const [checkReturn, setCheckReturn] = useState('f');
    const [checkAdd, setCheckAdd] = useState('f');
    
    const [sortValue,setSortValue]=useState('createdAt');
    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("profileRoomsPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dataBirthdayFrom" || id === "dataBirthdayTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
        localStorage.setItem("profileRoomsPage", JSON.stringify(updatedFilter));
    };

    const initFunc = (page = 1) => {
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        data.sort[sortValue]=sort;

        // dispatch(getUsersList(data)).then(() => {
            setReady(true);
        // });
    };

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready]);

    const resetFilter=()=>{
        localStorage.removeItem('profileRoomsPage');
        setFilter({});
    }


    return(<>
        <div className="profileSectionBlock prFlexRow">
            <div className="userEditLeft">
                <h2>{t('current_money')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h2>
                <div className="infoText">
                    {t('profile_wallet_current_info_text')}
                </div>
                <div className="profileSectionBlockMoneyInfo">
                    <h3>
                        <Icon type='fTocken'></Icon> {t('fiat')}
                    </h3>
                    <div class="token-info moneyInfoBlock">
                        <div class="token-row">
                            <span class="token-label">Вільні</span>
                            <span class="token-value green">400</span>
                            <span class="token-icon green">F</span>
                        </div>
                        <div class="token-row">
                            <span class="token-label">Зарезервовані</span>
                            <span class="token-value orange">32</span>
                            <span class="token-icon orange">F</span>
                        </div>
                    </div>
                                                    
                </div>
                <div className="profileSectionBlockMoneyInfo">
                    <h3>
                        <Icon type='cTocken'></Icon> {t('crypto')}
                    </h3>
                    <div class="token-info">
                        <div class="token-row">
                            <span class="token-label">Вільні</span>
                            <span class="token-value green">400</span>
                            <span class="token-icon green">C</span>
                        </div>
                        <div class="token-row">
                            <span class="token-label">Зарезервовані</span>
                            <span class="token-value orange">32</span>
                            <span class="token-icon orange">C</span>
                        </div>
                    </div>
                                                    
                </div>

            </div>
            <div className="userEditLeft">
                <h2>{t('add_money')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h2>
                <div className="infoText">
                   {t("current_currency")}
                </div>
                <div className="infoText">
                    1$ = 1 <Icon type='fTocken'></Icon> = 1 <Icon type='cTocken'></Icon>
                </div>
                <br />
                <div className="infoText">
                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                </div>
                <br />
                <div className="textWithInfoHelp">
                    {t("choose_tocken_type")} <div className="infoHelp"><Icon type='info' /></div>
                </div>
                <div className="radioSelect">
                    <div  onClick={()=>setCheckAdd('f')} className={checkAdd=='f'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckAdd('f')} type='fTocken'></Icon>
                    <div style={{marginLeft:'37px'}} onClick={()=>setCheckAdd('c')}  className={checkAdd=='c'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckAdd('c')} type='cTocken'></Icon>
                </div>

                <div className="textWithInfoHelp">
                    {t("tocken_buy")} <div className="infoHelp"><Icon type='info' /></div>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                    <input className="prInputInput" type="text" /> $ </div>
                <div className="textWithInfoHelp">
                    {t("choose_payment")} <div className="infoHelp"><Icon type='info' /></div>
                </div>
                <div className="prInput">
                    <select
                        className={ "prInputInput"}
                            id="paymentOrg"
                            onChange={changeHandler}
                        >
                        <option value="">{t('empty_select')}</option>
                        <option value={'fiat'}>PayPal</option>
                        <option value={'crypto'}>Payever</option>
                    </select>
                </div>
                <div className="dFlex jCC">
                    <button className="prButton prButtonMain">{t('add')}</button>
                </div>
                

            </div>
            <div className="userEditLeft">
                <h2>{t('return_money')}
                    <div className="infoHelp"><Icon type='info' /></div>
                </h2>
                <div className="infoText">
                   {t("current_currency")}
                </div>
                <div className="infoText">
                    1$ = 1 <Icon type='fTocken'></Icon> = 1 <Icon type='cTocken'></Icon>
                </div>
                <br />
                <div className="infoText">
                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                </div>
                <br />
                <div className="textWithInfoHelp">
                    {t("choose_tocken_type")} <div className="infoHelp"><Icon type='info' /></div>
                </div>
                <div className="radioSelect">
                    <div  onClick={()=>setCheckReturn('f')} className={checkReturn=='f'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckReturn('f')} type='fTocken'></Icon>
                    <div style={{marginLeft:'37px'}} onClick={()=>setCheckReturn('c')}  className={checkReturn=='c'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckReturn('c')} type='cTocken'></Icon>
                </div>

                <div className="textWithInfoHelp">
                    {t("tocken_return")} <div className="infoHelp"><Icon type='info' /></div>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                    <input className="prInputInput" type="text" /> $ </div>
               
                <div className="textWithInfoHelp">
                    {t("choose_payment")} <div className="infoHelp"><Icon type='info' /></div>
                </div>
                <div className="prInput">
                    <select
                        className={ "prInputInput"}
                            id="paymentOrg"
                            onChange={changeHandler}
                        >
                        <option value="">{t('empty_select')}</option>
                        <option value={'fiat'}>PayPal</option>
                        <option value={'crypto'}>Payever</option>
                    </select>
                </div>
                <div className="dFlex jCC">
                    <button className="prButton prButtonMain">{t('return')}</button>
                </div>
            </div>
        </div>



        <div className="profileSection">
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('my_transaction')}
                <div className="infoHelp"><Icon type='info'/></div></h2>
                <div className="prFlexRow" style={{gap:'25px'}}>
                    <button className="prButton prButtonMainP prButtonSecond prFlexRow" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
                {/* <a className="profileSectionHeaderLink link">{t('all_games')}<Icon type='rightarrow'/></a> */}
            </div>
            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dataBirthdayFrom" value={(filter.dataBirthdayFrom?.length>1 && getDate(filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dataBirthdayTo" value={(filter.dataBirthdayTo?.length>1 && getDate(filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <div className="filterBlockSortValue prFlexRow">
                                <div className={sort=='asc'?'norm':'pervert'}><Icon type="filterArrow" /></div>
                                <div>{sortOptions[0].value}</div>
                                <Icon type="selectArrow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {} {t("filter_all")} {}
                </div>
            </div>
            <div className="roomList">
                <div className="roomItem prFlexRow">
                    <div className="">
                        <div className="roomItemBlockHeader">{t('transaction_id')}</div>
                        <div className="roomItemBlockValue">ML02153658745</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('transaction_type')}</div>
                        <div className="roomItemBlockValue">Поповнення</div>
                    </div>
                    <div className="roomItemMoney roomItemFullBlock">
                        <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">Нова транзакція</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('payment_org')}</div>
                        <div className="roomItemBlockValue">Назва системи</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div> 

                <div className="roomItem prFlexRow">
                    <div className="">
                        <div className="roomItemBlockHeader">{t('transaction_id')}</div>
                        <div className="roomItemBlockValue">ML02153658745</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('transaction_type')}</div>
                        <div className="roomItemBlockValue">Поповнення</div>
                    </div>
                    <div className="roomItemMoney roomItemFullBlock">
                        <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">Нова транзакція</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('payment_org')}</div>
                        <div className="roomItemBlockValue">Назва системи</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div> 

                <div className="roomItem prFlexRow">
                    <div className="">
                        <div className="roomItemBlockHeader">{t('transaction_id')}</div>
                        <div className="roomItemBlockValue">ML02153658745</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('transaction_type')}</div>
                        <div className="roomItemBlockValue">Поповнення</div>
                    </div>
                    <div className="roomItemMoney roomItemFullBlock">
                        <div className="roomItemMoneyValue prFlexRow">20 <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">Нова транзакція</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('payment_org')}</div>
                        <div className="roomItemBlockValue">Назва системи</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> 16:22</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>22.05.2024</div>
                        </div>
                    </div>
                </div> 
                           
            </div>
        </div>
        {isOpen && <Filter type='transaction' resetFilter={resetFilter} accept={initFunc} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>)

}

export default ProfileWalletPage;