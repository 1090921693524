import { io } from 'socket.io-client';

const URL = 'wss://playroom.technodreams.biz';

export const socket = io(URL, {
  transports: ['websocket'],
  reconnectionAttempts: 5,  // Number of reconnection attempts before giving up
  reconnectionDelay: 2000,  // Delay between reconnection attempts
  timeout: 20000,           // Connection timeout
});

socket.on('connect', () => {
  console.log('Connected to the WebSocket server');
});

socket.on('disconnect', (reason) => {
  console.log(`Disconnected: ${reason}`);
});

socket.on('connect_error', (error) => {
  console.error(`Connection error: ${error.message}`);
});