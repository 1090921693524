import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const getGames = (data)=>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                
                filter+=`&filters[${key}]=${el}`
            });
        }
    }
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
    return axios.get(constants.URL + `games?offset=${data.offset}&limit=${data.limit}&sort[isPopularGame]=desc${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}
const getMyGames = (data={})=>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter+=`&filters[${key}]=${el}`
            });
        }
    }
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
    return axios.get(constants.URL + `games/management/my?offset=${data.offset}&limit=${data.limit}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}


const addGame = (data)=>{

        return axios.post(constants.URL + `games`,data,{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
            console.log(e);
        });
}

const editGame = (data)=>{
    return axios.put(constants.URL + 'games/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getGameItem = (data)=>{
    return axios.get(constants.URL +'games/'+data.id,{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const deleteGame = (data)=>{
    return axios.delete(constants.URL + 'games/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}
const addNickname =(body)=>{
    return axios
    .post(constants.URL + "games/management/add-nickname", body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
  }

export default{
    getGames,
    addGame,
    editGame,
    getGameItem,
    deleteGame,
    addNickname,
    getMyGames
}