import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import InfoWindow from "../InfoWindow/InfoWindow";

const Editor = (props) => {
  const { url = 'content-pages', value='', maxLength, disabled, onChange, onBlur } = props;
  const [editorInstance, setEditorInstance] = useState(null); // Store the editor instance
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    let editor;
    DecoupledEditor.create(document.querySelector('#editor'), {
      toolbar: DecoupledEditor.defaultConfig.toolbar.items.concat('mediaEmbed'),
      mediaEmbed: {
        previewsInData: true,
      },
    })
      .then((editorCreated) => {
        editor = editorCreated;
        setEditorInstance(editor); // Update the editor instance
        editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );

        createEditor(editor, url);
        editor.setData(value || '');
      })
      .catch((error) => {
        console.error('Error initializing the editor:', error);
      });
  }, [value, url]);

  useEffect(() => {
    if (editorInstance) {
      if (disabled) {
        editorInstance.enableReadOnlyMode('editor');
      } else {
        editorInstance.disableReadOnlyMode('editor');
      }
    }
  }, [disabled, editorInstance]);

  const handleEditorChange = (event, editor) => {
    const data = editorInstance.getData();
    if (data && data?.length > maxLength) {
      setErrorModal(true);
    } else {
      onChange && onChange(data);
    }
  };

  const handleEditorChangeSave = (event, editor) => {
    const data = editorInstance.getData();
    if (data && data?.length > maxLength) {
      setErrorModal(true);
    } else {
      onBlur && onBlur(data);
    }
  };

  return (
    <div className="visibleTextBlock">
      <div
        id="editor"
        style={{ minHeight: "300px" }}
        maxLength={maxLength}
        data={value}
        onChange={handleEditorChange}
        onBlur={handleEditorChangeSave}
      >
      </div>
      {/* {editorInstance?.getData()?.length || value.length}/{maxLength} */}

      {/* {errorModal &&
        <InfoWindow
          setModal={setErrorModal}
          message={'Максимальна кількість символів ' + maxLength}
        ></InfoWindow>} */}
    </div>
  );
};

function createEditor(editor, url) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    const currentLink = url || "content-pages";
    return new MyUploadAdapter(loader, currentLink);
  };
}

class MyUploadAdapter {
  constructor(props, currentLink) {
    this.loader = props;
    this.url = `https://ap.sportforall.gov.ua/api/v1/assets/`;
    this.link = currentLink;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open("POST", this.url + this.link, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Security-Policy", "none");
  }

  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("loadend", () => {
      const response = xhr.response;

      resolve({
        default: response.url,
      });
    });
  }

  _sendRequest() {
    const data = new FormData();

    this.loader.file.then((result) => {
      data.append("file", result);
      this.xhr.send(data);
    });
  }
}

export default Editor;
