import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import { Icon } from "../../components/Icon";
import Pagination from "../../components/Pagination/Pagination";
import { addNickname, getGames } from "../../redux/actions/game";


const GameLibraryPage =()=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ready, setReady] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [pageCur, setPageCur] = useState(1);
    const [form, setForm] = useState({});
    const game =useSelector((state) => state.game);

    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("gamesLibraryPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const addMeToGame=(elem)=>{
        let data = {...elem};
        if(data.platform?.length>0){

        }else{
            data.platform=[];
        }
        setForm(data);
        setFilterModal(true);
    }
    const selectClick=(e)=>{
        e.preventDefault();
        let arr = form.platform;
        let el = e.target.id;
        if (arr.indexOf(el) !== -1) {
            arr = arr.filter(item => item !== el);
        } else {
            arr.push(el);
        }
        setForm({
            ...form,
            platform: arr
        });
    }

    const saveFunc =()=>{
        let data={};
        data.id=form.id;
        data.platform=form.platformNew;
        dispatch(addNickname(data)).then(res=>{
            setFilterModal(false);
        })
    }

    const changeHandler =(e)=>{
        const { id, value } = e.target;
        let formChange = { ...form };
            if(formChange.platformNew && formChange.platformNew[id]){
                formChange.platformNew[id] = value;
            }else if(formChange.platformNew){
                formChange.platformNew[id] = value;
            }
            else{
                formChange.platformNew={};
                formChange.platformNew[id] = value;
            }
        setForm(formChange);
    }

    const initFunc =(page)=>{
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        dispatch(getGames(data)).then(res=>{
            setReady(true);
        })
    }

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    
    }, [ready]);


    if(ready){ 
    return(<>
    <div className="profileSection" style={{marginBottom:'0px'}}>
        <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
            <h2 >{t('games_library')}
                <div className="infoHelp"><Icon type='info'/></div>
            </h2>
        </div>
        <div className="gameList">
        {game?.items?.length>0 &&
        game?.items.map(el=>{
            return(<>
            <div className="gameItem gameItemPopular" style={{backgroundImage:`url(${el.iconGame})`}}>
                    <div className="gameItemEdit"><Icon type='bigplus'  onClick={()=>addMeToGame(el)}/></div>
                    <div className="gameItemBlockInfo" style={{cursor:'pointer'}} onClick={()=>navigate('/gameItem/'+el.id)}>
                        <div className=" prFlexRow profileMainBlockInfoRightText">
                            <div className="profileFio">{el.name}</div>
                                {el?.platforms?.map(elem=>{
                                    console.log(elem)
                                return(
                                    <>
                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                        <div style={{paddingTop:'3px'}}><Icon type='pc'></Icon></div>
                                    }
                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                        <div style={{paddingTop:'3px'}}><Icon type='ps'></Icon></div>
                                    }
                                    {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                        <div style={{paddingTop:'3px'}}><Icon type='xbox'></Icon></div>
                                    }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>)

        })
        }
        </div>
        {game.total>game.limit&& <Pagination
                        className="pagination-bar"
                        currentPage={pageCur}
                        totalCount={game.total}
                        pageSize={game.limit}
                        onPageChange={(page) => {
                            setPageCur(page);
                            initFunc(page);
                        }}
                    />}



    </div>


    {filterModal && <Filter type='addMyGame' accept={saveFunc} filter={form} setFilter={setForm} onChange={changeHandler} selectClick={selectClick} isOpen={filterModal} setIsOpen={setFilterModal} ></Filter>}

    </>)
}
}

export default GameLibraryPage;