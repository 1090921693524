import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';
import getDate from '../../helpers/getDate';

// Extend dayjs with the customParseFormat plugin
dayjs.extend(customParseFormat);

const DatePicker = (props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState( '');
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  // Refs for the input, calendar, and icon elements
  const inputRef = useRef(null);
  const calendarRef = useRef(null);
  const iconRef = useRef(null);


  useEffect(() => {
    if (selectedDate) {
      setCurrentMonth(selectedDate.startOf('month')); // Ensure month is updated
    }
  }, [selectedDate]);

  useEffect(() => {
    // Close the calendar if the user clicks outside of the input or calendar, but not the icon
    const handleClickOutside = (event) => {
      if (
        inputRef.current && !inputRef.current.contains(event.target) &&
        calendarRef.current && !calendarRef.current.contains(event.target) &&
        iconRef.current && !iconRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setInputValue(date.format('DD.MM.YYYY'));
    setIsCalendarOpen(false);
    const data={
      target:{
        id:props.id,
        value:date.format('DD.MM.YYYY')
      }
    }
    props.onChange(data);
  };

  const changeMonth = (direction) => {
    setCurrentMonth(currentMonth.add(direction, 'month'));
  };

  const formatInputValue = (value) => {
    const cleanedValue = value.replace(/\D/g, ''); // Remove all non-digit characters
    let formattedValue = '';

    if (cleanedValue.length > 0) {
      formattedValue += cleanedValue.substring(0, 2); // Day
    }
    if (cleanedValue.length > 2) {
      formattedValue += '.' + cleanedValue.substring(2, 4); // Month
    }
    if (cleanedValue.length > 4) {
      formattedValue += '.' + cleanedValue.substring(4, 8); // Year
    }

    return formattedValue;
  };


  useEffect(()=>{
    if(props.value?.length>0){
      let newDate= getDate(props.value,'dd/mm/yyyy','.');
      setInputValue(newDate);
    }else{
      setInputValue('');
    }
  },[props.value]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatInputValue(value);
    setInputValue(formattedValue);
    if (formattedValue.length === 10) { // Only validate and update calendar on full date
      validateAndSetDate(formattedValue);
    } else if (formattedValue.length === 7) { // Only update calendar on year input
      updateCalendarYear(formattedValue);
    }
  };

  const validateAndSetDate = (value) => {
    const parsedDate = dayjs(value, 'DD.MM.YYYY', true);
    if (parsedDate.isValid()) {
      setSelectedDate(parsedDate);
      setCurrentMonth(parsedDate.startOf('month')); // Update the calendar month
    } else {
      console.error('Invalid Date Format:', value);
      setSelectedDate(null);
    }
  };

  const updateCalendarYear = (value) => {
    const parts = value.split('.'); 
    let day = parts[0] || '01'; // Default to 1st if day is missing
    let month = parts[1] || '01'; // Default to January if month is missing
    let year = parts[2] || dayjs().year(); // Default to current year if year is missing

    // Construct a date string with the default day and month
    const formattedDate = `${day}.${month}.${year}`;
    const parsedDate = dayjs(formattedDate, 'DD.MM.YYYY', true);

    if (parsedDate.isValid()) {
      setCurrentMonth(parsedDate.startOf('month')); // Update calendar to the correct month
      if (selectedDate && selectedDate.year() !== parsedDate.year()) {
        setSelectedDate(null); // Clear selected date if year changes
      }
    } else {
      console.error('Invalid Date Format:', formattedDate);
    }
  };

  const handleBlur = (e) => {
    if (inputValue.length === 10) { // Only validate on full date
      validateAndSetDate(inputValue);
      props.onChange(e);
      props.onBlur();
    }
  };

  const daysInMonth = currentMonth.daysInMonth();
  const firstDayOfMonth = currentMonth.startOf('month').day();

  // Get month names from translation
  const monthName = t(currentMonth.format('MMMM'));

  return (
    <div className="date-picker">
      <input
        ref={inputRef}
        className="filterBlockInput"
        type="text"
        id={props.id}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder={t("dd_mm_yyyy")}
      />
      {props.icon && (
        <div
          ref={iconRef}
          className='dateIcon'
          onClick={toggleCalendar}
        >
          <Icon type='calendar' />
        </div>
      )}
      
      {isCalendarOpen && (
        <div ref={calendarRef} className="calendar">
          <div className="header prFlexRow">
            <button onClick={() => changeMonth(-1)}><Icon type='pagArrowLeft' /></button>
            <span>{`${monthName} ${currentMonth.format('YYYY')}`}</span>
            <button onClick={() => changeMonth(1)}><Icon type='pagArrow' /></button>
          </div>
          <div className="days">
            {[t('Mo'), t('Tu'), t('We'), t('Th'), t('Fr'), t('Sa'), t('Su')].map((day) => (
              <div key={day} className="day-name">
                {day}
              </div>
            ))}
            {Array.from({ length: firstDayOfMonth }).map((_, index) => (
              <div key={index} className="empty-day" />
            ))}
            {Array.from({ length: daysInMonth }).map((_, index) => {
              const day = index + 1;
              const isSelected = selectedDate && selectedDate.date() === day && selectedDate.month() === currentMonth.month() && selectedDate.year() === currentMonth.year();
              return (
                <div
                  key={day}
                  className={isSelected ? "day active" : "day"}
                  onClick={() => handleDateSelect(currentMonth.date(day))}
                >
                  {day}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
