import React, { useEffect, useState } from "react";
import '@google/model-viewer';


const StartPage = () => {
    return (<>
        </>
    );
};

export default StartPage