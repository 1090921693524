import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';
import { socket } from "../../socket";



const getUserDetail=(body)=>{
  console.log( authHeader());
  return axios
  .get(constants.URL + "users/"+body.id,{ headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return Promise.reject(e.response.data);
  });
}

const getUsersList=(body)=>{
  const offset = body.offset || 0;
  const limit = body.limit || 10;

  let filter = `?offset=${offset}&limit=${limit}`;

  if(body.filter&& Object.keys(body.filter).length ){
    for (let [key, value] of Object.entries(body.filter)) {
      filter+=`&filters[${key}]=${value}`
    }
  }
  if(body.sort&& Object.keys(body.sort).length ){
    for (let [key, value] of Object.entries(body.sort)) {
      filter+=`&sort[${key}]=${value}`
    }
  }
  return axios
  .get(`${constants.URL}users${filter}`, { headers: authHeader() })
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
}
const createUser = () => {
  return axios
    .post(constants.URL + "users", {}, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const editUser = (body) => {

  return axios
    .put(constants.URL + "users/"+body.id, body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const getUserReputation = (form) => {
  return axios
    .post(constants.URL + "reputation", form, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};



export default {
  getUserDetail,
  getUsersList,
  createUser,
  editUser,
  getUserReputation
};








