import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/Icon";
import { restore, restoreCode } from "../../../redux/actions/auth";


const RestorePage=()=>{
    const [show,setShow]=useState(false)
    const { t } = useTranslation();
    const navigate=useNavigate();
    const [form,setForm]=useState({})
    const dispatch = useDispatch();
    const [error,setError]=useState(false)
    const [errors, setErrors] = useState({});
    const [validForm, setValidForm] = useState({});

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
           
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };


    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const sendCode=()=>{
        if(!form.email){
            setValidForm({ ...validForm, 'email': false });
            setErrors({ ...errors, 'email':  t("invalid_email") });
        }else{
            dispatch(restoreCode({'email':form.email}))
            .then((res) => {
                // navigate('/login');
            })
            .catch((e) => {
                // handle error
            });
        }
        
    }

    const restoreHandler =(e)=>{
        dispatch(restore(form)).then(res=>{
            if(res.error){
                setError(true);
            }else{
                navigate('/login')
            }
        }).catch(e=>{

        })
    }
    const cancelHandler=(e)=>{
        navigate('/login')
    }

return(
    <div className="prFullHeight">
        <div className="prHeaderAuth"> {t("auth_restore" )} 
            <div className="infoHelp"><Icon type='info'/></div>
        </div>
        <div className="prInput">
                <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                <input onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                {errors.email && <div className="error">{errors.email}</div>}
            </div>
        <div className="prInput" style={{width:'340px'}}>
                <label htmlFor="code" className={errors.code ? "prInputLabel inputError" : "prInputLabel"}>{t("register_code")}</label>
                <div className="prFlexRow">
                    <button className="prButton prButtonMain prButtonhalfAuth" onClick={sendCode}>
                        {t('register_code_tooltip')}
                    </button>
                    <div className="halfWidth">
                        <input maxLength={6} onChange={changeHandler} onBlur={checkHandler} id="code" type={'text'} className={errors.code ? "prInputInput inputError" : "prInputInput"} placeholder={t("register_code")} />
                    </div>
                </div>
                {errors.code && <div className="error">{errors.code}</div>}
            </div>
            <div className="prInput prInputIcon">
                <label htmlFor="password" className={errors.password ? "prInputLabel inputError" : "prInputLabel"}>{t("new_password")}</label>
                <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="password" type={show ? 'text' : "password"} className={errors.password ? "prInputInput inputError" : "prInputInput"} placeholder={t("new_password")} />
                <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                {errors.password && <div className="error">{errors.password}</div>}
            </div>

        <button onClick={restoreHandler} className="prButton prButtonMain" style={{marginBottom:'20px',marginTop:'34px',width:'340px', textTransform:'none', height:'48px'}}>{t("save_new_password" )}</button>
        <button onClick={cancelHandler} className="prButton prButtonSecond" style={{marginBottom:'60px',width:'340px', textTransform:'none', height:'48px'}}>{t("cancel" )}</button>
       
    </div>
)

}


export default RestorePage;