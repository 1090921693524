import React from "react";


const AboutGameItemPage=(props)=>{


return(<>
 <div className="dangerouslySetInnerHTML" dangerouslySetInnerHTML={{ __html: props.game.description }}></div>
</>)
}

export default AboutGameItemPage;