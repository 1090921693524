const checkRole = (user, role) => {
    const currentRole = user.role;

    switch (role) {
        case 'arbitr':
            return (
                currentRole === '0b95c2cb-9ade-493a-999a-6aff5930c001' ||
                currentRole === '0b95c2cb-9ade-493a-999a-6aff5930c003' ||
                currentRole === '0b95c2cb-9ade-493a-999a-6aff5930c002'
            );
        case 'admin':
            return (
                currentRole === '0b95c2cb-9ade-493a-999a-6aff5930c001' ||
                currentRole === '0b95c2cb-9ade-493a-999a-6aff5930c002'
            );
        case 'superAdmin':
            return currentRole === '0b95c2cb-9ade-493a-999a-6aff5930c002';
        default:
            return false;
    }
};

export default checkRole;