import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEng from "./locales/eng/translation.json";
import translationRu from "./locales/ru/translation.json";
import translationUa from "./locales/ua/translation.json";
import translationDe from "./locales/de/translation.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: "ua",
    fallbackLng: "ua",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    },

    resources: {
      en: {
        translations: translationEng
      },
      ru: {
        translations: translationRu
      },
      ua: {
        translations: translationUa
      },
      de: {
        translations: translationDe
      }
    },
    ns: ["translations"],
    defaultNS: "translations"
  });


export const changeLanguage = (lng) => {
  localStorage.setItem('lng',JSON.stringify(lng))
  i18n.changeLanguage(lng);
};

export default i18n;